import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import './header.scss'
import ConnectWallet from "../ConnectWallet";
import { useNavigate } from 'react-router-dom';

const Header = ({ main, howToPlay, faq, contact, scrollRef, fixed }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState('main')

  const navigation = (menu) => {
    if (fixed === 'none') {
      navigate(`/#${menu}`)
      setActive(menu);
      return
    }
    setActive(menu);

    switch (menu) {
      case 'main':
        main()
      break;
      case 'howToPlay':
        howToPlay()
      break;
      case 'faq':
        faq()
      break;
      case 'contact':
        contact()
      break;
    }
  }

  return (
    <Navbar fixed={fixed} bg="dark" expand="lg" className="navigation-bar" ref={scrollRef} style={ fixed === 'none' ? { borderColor: '#79BE2B' } : {} }>
      <Container>
        <Navbar.Brand href="#" onClick={() => navigate(`/#main`)}><img src="img/logo_white.svg" loading="lazy" width="35" alt="" className="logo slotie-nav-logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mt-sm-5 mt-lg-0">
            <a className={`nav-link${active === 'main' ? ' active' : ''}`} onClick={() => navigation('main')}>Home</a>
            <a className={`nav-link${active === 'howToPlay' ? ' active' : ''}`} onClick={() => navigation('howToPlay')}>How To Play</a>
            <a className={`nav-link${active === 'faq' ? ' active' : ''}`} onClick={() => navigation('faq')}>Frequently Asked Questions</a>
            <a className={`nav-link${active === 'contact' ? ' active' : ''}`} onClick={() => navigation('contact')}>Contact</a>
            <div className="d-flex ms-3">
              <ConnectWallet />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
