import React from 'react'
import { Col, Row, Container, Accordion } from 'react-bootstrap'

const faqItems = [
    {
        title: "What is Slottery?",
        text: "Slottery is a Web 3 Wheel of Fortune where users can guess multiple numbers for a chance to win big."
    },
    {
        title: "What prizes can I win?",
        text: "You can win NFTs, WATTs bounties."
    },
    {
        title: "How do I play Slottery?",
        text: "First, you must connect your wallet to the website, always double check the URL. Then, select the game you wish to play. You must burn WATTS to select a game and play Slottery. Once you have chosen your game, you will enter the numbers you believe will hit. You can enter your numbers upfront, or live during the game."
    },
    {
        title: "What if the Slottery happens while I am asleep?",
        text: "You can place your bets upfront before the game starts. Even if you are offline during the live game, you can still participate and have a chance to win."
    },
    {
        title: "How many rounds are in a game of Slottery?",
        text: "The average Slottery has 10 rounds in each game. However users can join different Slottery types, and each type will have a unique number of rounds."
    },
    {
        title: "Who can play Slottery?",
        text: "Anyone who is holding WATTS in their wallet can join a game. Players should pay WATTS per game. Price of each game will be displayed on website."
    },
    {
        title: "What are WATTS?",
        text: "WATTS are the internal currency of SlotieVerse, the largest and fastest growing DeFi gambling network."
    },
    {
        title: "How do I get WATTS?",
        text: "The easiest way to get Internal Token WATTS is by owning a Slotie NFT, because each Slotie NFT generates 10 Internal WATTS Token per day. If you do not own a Slotie NFT"
    },
]

const Faq = ({ scrollRef }) => (
  <div className="faq" ref={scrollRef} style={{ backgroundImage: `url("img/faq_bg.png")` }}>
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h2 className="title">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="0">
              {
                  faqItems.map((item, i) => (
                      <>
                         <Accordion.Item eventKey={i}>
                            <Accordion.Header>{item.title}</Accordion.Header>
                            <Accordion.Body>
                                {item.text}
                            </Accordion.Body>
                        </Accordion.Item>
                      </>
                  ))
              }                    
          </Accordion>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Faq
