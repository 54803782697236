import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import "./app.scss"

import Index from "./pages/index.jsx"
import Upcoming from "./pages/Upcoming/index";
import Game from "./pages/Game/index";
import Terms from "./pages/Terms/index";
 
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path="/upcoming" element={<Upcoming />} />
          <Route path="/game" element={<Game />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
