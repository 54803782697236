import { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import {  
    isProviderExists,
    isObjectExists
} from "../utils/index"

export default function useDiscord(provider, firestore) {
    const [walletDoc, setWalletDoc] = useState();
    const [discordName, setDiscordName] = useState();

    async function getWallet() {        
        if (isProviderExists(provider) && isObjectExists(firestore)) {
            try {                
                const walletDoc = await getDoc(doc(firestore, "wallets", provider.address.toLowerCase()));
                if (walletDoc.exists()) {                   
                    const data = walletDoc.data()
                    setWalletDoc(data)
                    setDiscordName(data.discord || "")
                }
            } catch (e) {
                console.log(e)
            }            
        }
    }

    useEffect(() => {
        getWallet()
    }, [provider, firestore])

    return [
        walletDoc,
        discordName,
        setWalletDoc,
        setDiscordName
    ]
}