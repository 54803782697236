import { useState, useEffect } from 'react';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeApp } from 'firebase/app';

import { FIRESTORE, NETWORK } from '../config';

export function getFireBase() {
    let firebaseConfig = FIRESTORE[NETWORK.TARGET_CHAIN_ID.toString()]
    const app = initializeApp(firebaseConfig)

    const firestore = getFirestore(app);
    const functions = getFunctions(app);

    if (NETWORK.TARGET_CHAIN_ID === 1337) {
        connectFirestoreEmulator(firestore, 'localhost', 8081);
        connectFunctionsEmulator(functions, 'localhost', 5001);
    }

    return [firestore, functions]
}