export const NETWORK = {
    TARGET_CHAIN_ID: 56,
    CHAIN_ID_TO_NAME: {
        "1": "mainnet",
        "5": "goerli",
        "56": "bsc",
        "97": "bsc_testnet",
        "1337": "localhost"
    },
    INFURA_ID: "e26409fe0a7343458a12e059c7ada225",
    PORTIS_ID: "",
    FORTMATIC_ID: ""
}

export const CONTRACTS = {
    //SLOTIE: "Slotie",
    WATTS: "WATTSBsc",
    SLOTTERY: "SlotteryBsc",
}

export const STORAGE = {
    AUTH_KEY: "slottery_auth_key_cjut542",
    SETTINGS_KEY: "slottery_settings_key_udjrsdwk1"
}

export const EXTERNAL_URLS = {
    etherscan: `https://bscscan.io/tx/`,
}

export const FIRESTORE = {
    "1": {
        apiKey: "AIzaSyDrDPeO5NrSnvzc85fDdEY_wx7ZKaiyLLU",
        authDomain: "slotie-baa44.firebaseapp.com",
        projectId: "slotie-baa44",
        storageBucket: "slotie-baa44.appspot.com",
        messagingSenderId: "620052493222",
        appId: "1:620052493222:web:96c2f343d1d8e0f2381567"
    },
    "5": {
        apiKey: "AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI",
        authDomain: "slotie-test.firebaseapp.com",
        projectId: "slotie-test",
        storageBucket: "slotie-test.appspot.com",
        messagingSenderId: "254135996243",
        appId: "1:254135996243:web:c8a46a81f1cfc8d2fa9ba6",
        measurementId: "G-PCTKTBYCQ8"
    },
    "56": {
        apiKey: "AIzaSyDrDPeO5NrSnvzc85fDdEY_wx7ZKaiyLLU",
        authDomain: "slotie-baa44.firebaseapp.com",
        projectId: "slotie-baa44",
        storageBucket: "slotie-baa44.appspot.com",
        messagingSenderId: "620052493222",
        appId: "1:620052493222:web:96c2f343d1d8e0f2381567"
    },
    "97": {
        apiKey: "AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI",
        authDomain: "slotie-test.firebaseapp.com",
        projectId: "slotie-test",
        storageBucket: "slotie-test.appspot.com",
        messagingSenderId: "254135996243",
        appId: "1:254135996243:web:c8a46a81f1cfc8d2fa9ba6",
        measurementId: "G-PCTKTBYCQ8"
    },
    "1337": {
        apiKey: "AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI",
        authDomain: "slotie-test.firebaseapp.com",
        projectId: "slotie-test",
        storageBucket: "slotie-test.appspot.com",
        messagingSenderId: "254135996243",
        appId: "1:254135996243:web:c8a46a81f1cfc8d2fa9ba6",
        measurementId: "G-PCTKTBYCQ8"
    }
}

export const DATA = {
    adminSpinUrl: {
        "1": "https://us-central1-slotie-baa44.cloudfunctions.net/slotteryAdminSpin",
        "5": "https://us-central1-slotie-test.cloudfunctions.net/slotteryAdminSpin",
        "56": "https://us-central1-slotie-baa44.cloudfunctions.net/slotteryAdminSpin",
        "97": "https://us-central1-slotie-test.cloudfunctions.net/slotteryAdminSpin",
        "1337": "" 
    },
    discordLoginUrl: {
        "1": "https://us-central1-slotie-baa44.cloudfunctions.net/api/login",
        "5": "https://us-central1-slotie-test.cloudfunctions.net/api/login",
        "56": "https://us-central1-slotie-baa44.cloudfunctions.net/api/login",
        "97": "https://us-central1-slotie-test.cloudfunctions.net/api/login",
        "1337": ""
    },
    discordSubmitWalletUrl: {
        "1": "https://discord.com/api/oauth2/authorize?client_id=1003933590612090921&redirect_uri=https%3A%2F%2Fslottery.slotie.com%2Fupcoming&response_type=code&scope=identify",
        "5": "https://discord.com/api/oauth2/authorize?client_id=1002570268985786449&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fupcoming&response_type=code&scope=identify",
        "56": "https://discord.com/api/oauth2/authorize?client_id=1003933590612090921&redirect_uri=https%3A%2F%2Fslottery.slotie.com%2Fupcoming&response_type=code&scope=identify",
        "97": "https://discord.com/api/oauth2/authorize?client_id=1002570268985786449&redirect_uri=https%3A%2F%2Fslottery-bsc-test.netlify.app%2Fupcoming&response_type=code&scope=identify",
        "1337": ""
    }
}

export const ERRORS = {
    MESSAGES: [
        {
            error: "MetaMask Tx Signature: User denied transaction signature",
            message: "Error: You rejected the transaction."
        },
        {
            error: "insufficient funds for gas * price + value",
            message: "Error: You don't have enough Ether in your wallet to complete the transaction.",
            insert: true
        },
    ]
}