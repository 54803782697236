import React, { useState, useEffect } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import './main.scss'
import Button from "../../components/Buttons";
import MoveAction from "../../components/Buttons/MoveAction";
import { useNavigate } from 'react-router-dom';

const Main = ({ goToScroll, goToPlay, scrollRef }) => {
  const navigate = useNavigate();
  const [className, setClass] = useState('')

  const clickWheel = () => {
    setClass('spin')
  }

  useEffect(() => {
    if (className) {
      setTimeout(() => {
        setClass('')
      }, 800)
    }
  }, [className])

  return (
    <div className="main-section" ref={scrollRef} style={{ backgroundImage: `url("img/header_bg.png")` }}>
      <Container>
        <Row>
          <Col lg={5} className="d-flex">
            <div className="left-cont">
              <span className="line">SLOTTERY</span>
              <h2>Play & Get WATTS</h2>
              <p>Welcome to web3's most innovative wheel of fortune that rewards you while playing. Play and get WATTS, Slotie & Slotie Junior NFTs, and more. Slottery beta is now live.</p>
              <div className="buttons">
                <Button type="dark" text="How To Play" onClick={goToPlay} />
                <Button type="join" text="Join The Game" onClick={() => navigate('/upcoming')} className="ms-3" />
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div className="right-cont">
              <div className="images-cont">
                <div className="back-image-left">
                  <img src="/img/spin_dark.png" alt=""/>
                </div>
                <div className="front-image">
                  <img className={className} onClick={clickWheel} src="/img/spin.png" alt=""/>
                </div>
                <div className="back-image-right">
                  <img src="/img/spin_dark.png" alt=""/>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <MoveAction onClick={goToScroll} />
      </Container>
    </div>
  )
}

export default Main
