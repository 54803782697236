import React from 'react'

const ConnectWalletMessage = ({ upcoming }) => (
    <>
        <tr style={{backgroundColor: "transparent"}}>
            <td style={{border: "none"}}></td>
            <td style={{border: "none"}}></td>
            <td style={{border: "none"}}></td>
            <td style={{border: "none"}}>Please connect your wallet to see the  games</td>
            <td style={{border: "none"}}></td>
            <td style={{border: "none"}}></td>
            <td style={{border: "none"}}></td>
        </tr>
    </>
)

export default ConnectWalletMessage
