import { STORAGE } from "../../config"

export function getAuthStorageData(walletAddress) {
    let storageData = localStorage.getItem(STORAGE.AUTH_KEY);
    if (!storageData)
        return undefined;
    let storageJson = JSON.parse(storageData);
    let walletStoredData = storageJson[walletAddress.toLowerCase()];
    return walletStoredData;
}

export function saveAuthStorageData(walletAddress, message, timestamp) {
    let storageData = localStorage.getItem(STORAGE.AUTH_KEY);
    if (!storageData)
        storageData = "{}"
    let storageJson = JSON.parse(storageData);
    storageJson[walletAddress.toLowerCase()] = { message, timestamp };
    localStorage.setItem(STORAGE.AUTH_KEY, JSON.stringify(storageJson));                        
}