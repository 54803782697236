import React, { useState, useEffect } from "react"
import useDiscord from "../../../hooks/useDiscord";

import ChatItem from "../../../components/ChatItem/ChatItem";
import ConnectWallet from "../../../components/ConnectWallet";

import { useCollection } from 'react-firebase-hooks/firestore';
import {      
    collection, 
    query, 
    addDoc, 
    orderBy, 
    limit,
    Query
} from 'firebase/firestore';

import {
    getTruncatedAddress,
    isObjectExists,
    isProviderExists
} from "../../../utils"

export default function ChatBox({ 
    provider,
    firestore,
    gameId
}) {
    const [
        ,
        discordName,
        ,
        ,
    ] = useDiscord(provider, firestore)

    const [
        firebase_messages, 
        , 
        firebase_messages_error
    ] = useCollection(query(collection(firestore, "messages"), orderBy("createdAt"), limit(5000)), { snapshotListenOptions: { includeMetadataChanges: true } });

    const [messages, setMessages] = useState([])
    const [chatVal, setChatVal] = useState("")

    function loadMessages() {        
        if (isObjectExists(firebase_messages) && !firebase_messages_error) {
            let docs = firebase_messages.docs;
            let gameMessages = docs.map(d => d.data()).filter(d => d.gameId === parseInt(gameId))            
            gameMessages.sort((a,b) => a.createdAt - b.createdAt)
            setMessages(gameMessages)
        }
    }

    const addMessage = (e) => {
        e.preventDefault()

        if (chatVal && chatVal !== "") {
            addDoc(collection(firestore, "messages"), {
                createdAt: new Date(),
                gameId: parseInt(gameId),
                text: chatVal,
                sender: provider.address.toLowerCase(),
                discord: discordName
            })
        }

        setChatVal("")
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
        addMessage(e)
        }
    }

    useEffect(() => {
        loadMessages();
    }, [firebase_messages, firebase_messages_error])
    
    
    return (
        <>
            {
                isProviderExists(provider) ?
                    <div className="chat-container">
                        <div className="chat-head">
                            <h3>Slottery Chat</h3>
                            {/*<div><span className="online">Online: </span> <span className="users">183 users</span></div>*/}
                        </div>
                        <div className="chat-body">
                            {
                                messages.map(m => {
                                    return (
                                      <ChatItem avatar="/img/avatar.png" message={m.text} username={m.discord ? m.discord : getTruncatedAddress(m.sender)} isCurrent={m.sender.toLowerCase() === provider.address.toLowerCase()} />
                                  )
                              })
                          }
                        </div>
                        <div className="chat-input">
                        <textarea value={chatVal} rows="3" onKeyDown={handleKeyDown} onChange={(e) => {
                            if (e.target.value.length <= 220)
                                setChatVal(e.target.value)
                        }} />
                        <button type="submit" onClick={addMessage}>
                            <svg width="25" height="18" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.25 1.25V4.25H3.3725L6.0575 1.5575L5 0.5L0.5 5L5 9.5L6.0575 8.4425L3.3725 5.75H14.75V1.25H13.25Z" fill="white"/>
                            </svg>
                        </button>
                        <div className="emoji-box">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#9A9EAA" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.5">
                                <path d="M15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11Z" fill="#9A9EAA"/>
                                <path d="M8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11Z" fill="#9A9EAA"/>
                                <path d="M12 18C14.28 18 16.22 16.34 17 14H7C7.78 16.34 9.72 18 12 18Z" fill="#9A9EAA"/>
                                <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#9A9EAA"/>
                            </g>
                            </svg>
                            <p>{220 - chatVal.length} Symbol</p>
                        </div>
                        </div>
                    </div>
                    :
                    <div className="chat-container" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <ConnectWallet />
                    </div>
              }
        </>
    )
}