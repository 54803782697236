import React, { useState, useEffect } from "react"
import { Col, Row } from 'react-bootstrap'
import { ethers } from "ethers"

import axios from "axios"

import { useDocument } from 'react-firebase-hooks/firestore';
import {
    getFirestore,
    doc,
    updateDoc,
    connectFirestoreEmulator
} from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

import AutoSpinPopup from "../../../components/AutoSpinPopup"

import Wheel from "../../../components/Wheel";
import WinningPopup from "../../../components/WiningPopup";
import BetItem from "../../../components/BetItem";

import { Oval } from  'react-loader-spinner'

import { NETWORK, DATA } from "../../../config"

import {
    gameDocSpec,
    isNullOrUndefined,
    unixTimeStampToDate,
    timeout,
    validateDiscord,
    isObjectExists,
    isArrayExists,
    isProviderExists
} from "../../../utils"

import useUserSettings from "../../../hooks/useUserSettings";

export default function GameCore({
    provider,
    gameDoc,
    gameId,
    firestore,
    functions,
    authSignature,
    startTime,
    now,
    setIsNotification,
    setNotificationTitle,
    setNotificationText,
    setNotificationClosable,
    gameAdmin,
    setTriggerLeaderboardUpdate,
    getUserAuth
}) {
    const [betItems, setBetItems] = useState([]);
    const [maxSpinNum, setMaxSpinNum] = useState();
    const [initialized, setInitialized] = useState();
    const [disabled, setDisabled] = useState();
    const [spinDisabled, setSpinDisabled] = useState();
    const [wheelItem, setWheelItem] = useState()
    const [spinnerItems, setSpinnerItems] = useState([]);
    const [extraBet, setExtraBet] = useState({})
    const [spinning, setSpinning] = useState(false)

    const [lastSpunRound, setLastSpunRound] = useState(-1);
    const [lastSpunNumber, setLastSpunNumber] = useState(0);
    const [lastSpunReward, setLastSpunReward] = useState(0);
    const [spinPopupActive, setSpinPopupActive] = useState();

    const [isAutoSpin, setIsAutoSpin] = useState();
    const [isAutoSpinPopupActive, setIsAutoSpinPopupActive] = useState();
    const [autoSpinTrigger, setAutoSpinTrigger] = useState(0);

    const [isAdminSpin, setIsAdminSpin] = useState();
    const [pendingSpins, setPendingSpins] = useState([])
    const [pendingSpinsTrigger, setPendingSpinsTrigger] = useState(0);
    const [pendingBet, setPendingBet] = useState({})
    const [pendingNextBet, setPendingNextBet] = useState({})
    const [adminSpinsDone, setAdminSpinsDone] = useState()

    const [
        ,
        updateUserSettingsSpecificValue,
        getUserSettingsOfGame 
    ] = useUserSettings()

    /** === HELPERS === */

    function configureWheel(from, to) {
        const items = []; // if you gets array of items, get rid of from, to variables.

        for (let i = from; i <= to; i++) {
            items.push(i)
        }

        setSpinnerItems(items)
    }

    async function setWheelNumber(num) {
        if (wheelItem === null) {
            setWheelItem(num);
        } else {
            setWheelItem(null)
            await timeout(200)
            setWheelItem(num)
        }

        await timeout(4500)

        //setSpinPopupActive(true)
        //setTimeout(() => {
        //    setSpinPopupActive(false)
        //}, 3000);
    }

    function getNextBetRound(rounds, additionalBets) {
        if (isObjectExists(gameDoc) && isProviderExists(provider)) {
            let { userBets, isAdmin } = gameDoc;
            let betsOfUser = userBets[provider.address.toLowerCase()];

            betsOfUser = betsOfUser ? { ...betsOfUser,  ...additionalBets } : additionalBets

            if (!betsOfUser)
                betsOfUser = {}

            let lowestNotBettedRound;

            if (isAdmin) {
                let { spins } = gameDoc;

                for (let i = 0; i < rounds; i++) {
                    let round = i + 1;
                    let notSpinned = spins.length < round;
                    if (!betsOfUser[round] && notSpinned) {
                        lowestNotBettedRound = round
                        break;
                    }
                }
            } else {
                for (let i = 0; i < rounds; i++) {
                    let round = i + 1;
                    if (!betsOfUser[round]) {
                        lowestNotBettedRound = round
                        break;
                    }
                }
            }            

            return lowestNotBettedRound;
        }
    }

    function setBetItemLoading(_betItems, round, state) {
        let betItemsCopy = JSON.parse(JSON.stringify(_betItems));
        let betItem = betItemsCopy[round - 1];
        if (betItem) {
            betItem.loading = state;
            betItemsCopy[round - 1] = betItem;
        }
        return betItemsCopy
    }

    function setBetItemBet(_betItems, round, bet) {
        let betItemsCopy = JSON.parse(JSON.stringify(_betItems));
        let betItem = betItemsCopy[round - 1];
        if (betItem) {
            betItem.placedBet = bet;
            betItemsCopy[round - 1] = betItem;
        }
        return betItemsCopy
    }

    function setBetItemSpin(_betItems, round, spin) {
        let betItemsCopy = JSON.parse(JSON.stringify(_betItems));
        let betItem = betItemsCopy[round - 1];
        if (betItem) {
            betItem.spunNumber = spin;
            betItemsCopy[round - 1] = betItem;
        }
        return betItemsCopy
    }

    function setBetItemDisabled(_betItems, round, state) {
        let betItemsCopy = JSON.parse(JSON.stringify(_betItems));
        let betItem = betItemsCopy[round - 1];
        if (betItem) {
            betItem.disabled = state;
            betItemsCopy[round - 1] = betItem;
        }
        return betItemsCopy
    }

    function getNextSpinRound(rounds, additionalbets, additionalspins) {
        if (isObjectExists(gameDoc) && isProviderExists(provider)) {
            let { userSpins, userBets } = gameDoc;
            let spinsOfUser = userSpins[provider.address.toLowerCase()];
            let betsOfUser = userBets[provider.address.toLowerCase()];

            betsOfUser = betsOfUser ? { ...betsOfUser, ...additionalbets } : additionalbets

            if (!betsOfUser)
                return undefined;

            spinsOfUser = spinsOfUser ? { ...spinsOfUser, ...additionalspins } : additionalspins

            if (!spinsOfUser)
                return 1;

            let lowestNotSpunRound;

            for (let i = 0; i < rounds; i++) {
                let round = i + 1;
                if (!spinsOfUser[round] && betsOfUser[round]) {
                    lowestNotSpunRound = round
                    break;
                }
            }

            return lowestNotSpunRound;
        }
    }

    function updateSpinState(rounds, additionalbets, additionalspins) {
        let nextSpinRound = getNextSpinRound(rounds, additionalbets, additionalspins);

        if (!nextSpinRound) {
            setSpinDisabled(true);
        } else {
            setSpinDisabled(false)
        }
    }

    const playSound = () => {
        if (document.querySelector("#spinAudio")) {
            document.querySelector("#spinAudio").play()
        }
    }

    async function autoSpin() {
        if (isObjectExists(gameDoc) && isProviderExists(provider)) {
            let { rounds } = gameDoc;

            if (
                isAutoSpin
            ) {
                let nextSpinRound = getNextSpinRound(rounds, extraBet, {});
                if (!isNullOrUndefined(nextSpinRound)) {
                    await onSpin();
                    setAutoSpinTrigger(_ => _ + 1)
                }
            }
        }
    }

    async function handlePendingSpins() {
        if (pendingSpins.length > 0) {
            let pendingSpinsCopy = JSON.parse(JSON.stringify(pendingSpins));
            let pendingSpin = pendingSpinsCopy.shift();

            setDisabled(true)

            const interval = setInterval(() => {
                playSound()
            }, 5)

            setTimeout(() => {
                clearInterval(interval);
            }, 4000)

            await setWheelNumber(pendingSpin.spin - 1);
            await timeout(250);

            setPendingSpins(pendingSpinsCopy)

            let { userBets, rounds } = gameDoc
            let betsOfUser = userBets ? userBets[provider.address.toLowerCase()] : {}
            let _pendingBet = {
                round: pendingSpin.round,
                loading: false,
                disabled: true,
                placedBet: betsOfUser ? betsOfUser[pendingSpin.round] ? betsOfUser[pendingSpin.round] : 0 : 0,
                spunNumber: pendingSpin.spin
            }            

            setPendingBet(_pendingBet)

            if (pendingSpins.length === 1 && pendingSpin.round < rounds) {
                let { userBets } = gameDoc
                let betsOfUser = userBets ? userBets[provider.address.toLowerCase()] : {}
                let placedBet = betsOfUser ? betsOfUser[pendingSpin.round + 1] ? betsOfUser[pendingSpin.round + 1] : 0 : 0
                let _pendingBet = {
                    round: pendingSpin.round + 1,
                    loading: false,
                    disabled: placedBet > 0,
                    placedBet: placedBet,
                    spunNumber: 0
                }
                setPendingNextBet(_pendingBet)
            }            
        } else {
            setDisabled(false);

        }
    }    

    async function handlePendingBet() {
        if (pendingBet && Object.keys(pendingBet).length > 0) {
            let betItemsCopy = JSON.parse(JSON.stringify(betItems))
            betItemsCopy[pendingBet.round - 1] = pendingBet;
            setBetItems(betItemsCopy);
            setPendingBet({})

            showPopup(pendingBet.round, pendingBet.spunNumber, pendingBet.placedBet === pendingBet.spunNumber ? 100 : 0);
            await timeout(3000)
            setSpinPopupActive(false)
            
            if (pendingSpins.length === 0) {
                setTriggerLeaderboardUpdate(_ => _ + 1);
            }

            setPendingSpinsTrigger(_ => _ + 1)
        }
    }

    function handlePendingNextBet() {
        if (pendingNextBet && Object.keys(pendingNextBet).length > 0) {
            let betItemsCopy = JSON.parse(JSON.stringify(betItems))
            betItemsCopy[pendingNextBet.round - 1] = pendingNextBet;
            setBetItems(betItemsCopy);
            setPendingNextBet({})            
        }
    }

    function createAdminSpinSignatureMessage(gameId, round) {
        return "Slottery Admin Spin: Game #" + gameId.toString() + " Round #" + round.toString()
    }

    function showPopup(round, winningNumber, points) {
        setLastSpunRound(round);
        setLastSpunNumber(winningNumber);
        setLastSpunReward(points)
        setSpinPopupActive(true);
    }

    /** === LOADING & UPDATE === */
    async function initialize() {       
        if (!initialized && isObjectExists(gameDoc) && isProviderExists(provider) && authSignature) {
            try {           
                setInitialized(true);

                let {
                    spinNum,
                    rounds,
                    userSpins,
                    userBets,
                    isAdmin,
                    spins,
                    initialRounds
                } = gameDoc;
                let userSettings = getUserSettingsOfGame(provider.address.toLowerCase(), gameId)

                configureWheel(1, spinNum);
                setMaxSpinNum(spinNum);

                let betsOfUser = userBets[provider.address.toLowerCase()];
                let winningNumbers = {}
                
                if (isAdmin) {
                    let lastDisplayedSpinRound = 0;
                    
                    if (
                        userSettings &&
                        userSettings.lastDisplayedSpinRound                   
                    ) {
                        lastDisplayedSpinRound = userSettings.lastDisplayedSpinRound;
                    }

                    winningNumbers = spins
                                .filter((s, i) => (i + 1) <= lastDisplayedSpinRound)
                                .reduce((acc, v, i) => {
                                    let round = i + 1;
                                    acc[round] = v
                                    return acc;
                                }, {})
                } else {
                    let spinsOfUser = isAdmin ? {} : userSpins[provider.address.toLowerCase()]
                    winningNumbers = spinsOfUser ? spinsOfUser : {}
                }

                let nextBetRound = getNextBetRound(rounds)

                let _betItems = []
                for (let i = 0; i < rounds; i++) {
                    let round = i + 1;
                    let roundName = round
                    if (round > initialRounds) {
                        roundName = "extra"
                    }
                    _betItems.push({
                        round: roundName,
                        loading: false,
                        disabled: round !== nextBetRound,
                        placedBet: betsOfUser ? betsOfUser[round] ? betsOfUser[round] : 0 : 0,
                        spunNumber: winningNumbers[round] ? winningNumbers[round] : 0
                    })
                }

                setBetItems(_betItems);
                updateSpinState(rounds);

                if (
                    userSettings &&
                    userSettings.autoSpin &&
                    !isAdmin
                ) {
                    setIsAutoSpin(true);
                }

                if (isAdmin) {
                    setIsAdminSpin(true);

                    let lastDisplayedSpinRound = 0;                

                    if (userSettings.lastDisplayedSpinRound)
                        lastDisplayedSpinRound = userSettings.lastDisplayedSpinRound

                    let _pendingSpins = spins.map((s, i) => ({ round: i + 1, spin: s }))
                                            .filter(s => s.round > lastDisplayedSpinRound)

                    if (_pendingSpins.length > 0) {                    
                        updateUserSettingsSpecificValue(provider.address.toLowerCase(), gameId, "lastDisplayedSpinRound", spins.length);
                        setPendingSpins(_pendingSpins)
                        setPendingSpinsTrigger(_ => _ + 1);
                    }

                    setAdminSpinsDone(spins.length)
                }

                setTriggerLeaderboardUpdate(_ => _ + 1)
            } catch (e) {
                console.log(e)
            }
        }
    }

    async function dynamicUpdate() {
        if (initialized && isObjectExists(gameDoc) && isProviderExists(provider)) {
            try {
                let {
                    spinNum,
                    rounds,
                    spins,
                    userSpins,
                    userBets,                
                    isAdmin,
                    initialRounds
                } = gameDoc;
                let userSettings = getUserSettingsOfGame(provider.address.toLowerCase(), gameId)

                if (spinNum !== maxSpinNum) {
                    configureWheel(1, spinNum);
                    setMaxSpinNum(spinNum);
                }

                if (rounds !== betItems.length) {
                    let betsOfUser = userBets[provider.address.toLowerCase()];
                    let winningNumbers = {}

                    if (isAdmin) {
                        let lastDisplayedSpinRound = 0;
                        if (
                            userSettings &&
                            userSettings.lastDisplayedSpinRound
                        ) {
                            lastDisplayedSpinRound = userSettings.lastDisplayedSpinRound;
                        }

                        winningNumbers = spins
                                    .filter((s, i) => (i + 1) <= lastDisplayedSpinRound)
                                    .reduce((acc, v, i) => {
                                        let round = i + 1;
                                        acc[round] = v
                                        return acc;
                                    }, {})
                    } else {
                        let spinsOfUser = isAdmin ? {} : userSpins[provider.address.toLowerCase()]
                        winningNumbers = spinsOfUser ? spinsOfUser : {}
                    }

                    let nextBetRound = getNextBetRound(rounds)
                    let _betItems = []
                    for (let i = 0; i < rounds; i++) {
                        let round = i + 1;
                        let roundName = round
                        if (round > initialRounds) {
                            roundName = "extra"
                        }
                        _betItems.push({
                            round: roundName,
                            loading: false,
                            disabled: round !== nextBetRound,
                            placedBet: betsOfUser ? betsOfUser[round] ? betsOfUser[round] : 0 : 0,
                            spunNumber: winningNumbers[round] ? winningNumbers[round] : 0
                        })
                    }

                    setBetItems(_betItems);
                    updateSpinState(rounds);
                }

                if (isAdmin && adminSpinsDone < spins.length) {
                    setIsAdminSpin(true);

                    let lastDisplayedSpinRound = 0;                

                    if (userSettings.lastDisplayedSpinRound)
                        lastDisplayedSpinRound = userSettings.lastDisplayedSpinRound

                    let _pendingSpins = spins.map((s, i) => ({ round: i + 1, spin: s }))
                                            .filter(s => s.round > lastDisplayedSpinRound)
                                            

                    if (_pendingSpins.length > 0) {
                        updateUserSettingsSpecificValue(provider.address.toLowerCase(), gameId, "lastDisplayedSpinRound", spins.length);
                        setPendingSpins(_pendingSpins)
                        setPendingSpinsTrigger(_ => _ + 1);                   
                    }

                    setAdminSpinsDone(spins.length)       
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    /** === TRIGGERS === */
    const onBet = async (bet) => {
        if (!isProviderExists(provider))
            return;

        try {
            const slotteryPlaceBet = httpsCallable(functions, 'slotteryPlaceBet');

            let { rounds } = gameDoc;
            let nextBetRound = getNextBetRound(rounds)
            let nextNextBetRound;

            setDisabled(true);
            let _betItems = setBetItemLoading(betItems, nextBetRound, true)
            setBetItems(_betItems);

            try {
                let result = await slotteryPlaceBet({
                    gameId,
                    address: provider.address,
                    round: nextBetRound,
                    spin: bet,
                    signature: authSignature
                });

                if (result) {
                    let { success } = result.data
                    if (success) {
                        _betItems = setBetItemBet(_betItems, nextBetRound, bet)
                        _betItems = setBetItemDisabled(_betItems, nextBetRound, true);

                        nextNextBetRound = getNextBetRound(rounds, { [nextBetRound]: bet });
                        setExtraBet({ [nextBetRound]: bet })
                        _betItems = setBetItemDisabled(_betItems, nextNextBetRound, false);
                    } else {
                        let { reason, refreshAuth } = result.data
                        if (refreshAuth) {
                            setNotificationTitle("Your signature has expired.")
                            setNotificationText("For security reasons we need you to sign a new signature. Please wait for your wallet signature to load.")
                            getUserAuth();
                            setDisabled(false);
                            _betItems = setBetItemLoading(_betItems, nextBetRound, false)
                            setBetItems(_betItems);
                            setAutoSpinTrigger(_ => _ + 1)
                            return;
                        } else {
                            alert(reason);
                        }                        
                    }
                }
            } catch (e) {
                console.log(e)
            }

            setDisabled(false);

            updateSpinState(rounds, { [nextBetRound]: bet }, {})

            _betItems = setBetItemLoading(_betItems, nextBetRound, false)
            setBetItems(_betItems);

            setAutoSpinTrigger(_ => _ + 1)
        } catch (e) {
            console.log(e)
        }
    }

    const onSpin = async () => {
        if (!isProviderExists(provider))
            return;
        try {
            const slotteryUserSpin = httpsCallable(functions, 'slotteryUserSpin');

            let { rounds } = gameDoc;
            let nextSpinRound = getNextSpinRound(rounds)

            setDisabled(true);

            try {
                let res = await slotteryUserSpin({
                    gameId,
                    round: nextSpinRound,
                    address: provider.address,
                    signature: authSignature
                })

                if (res && res.data && res.data.success) {
                    const interval = setInterval(() => {
                        playSound()
                    }, 5)

                    setTimeout(() => {
                        clearInterval(interval);
                    }, 4000)

                    let spunNumber = parseInt(res.data.spin)
                    await setWheelNumber(spunNumber - 1);
                    await timeout(250);

                    let _currentBetItem = betItems[nextSpinRound - 1]
                    let bet = _currentBetItem.placedBet
                    showPopup(nextSpinRound, spunNumber, spunNumber === bet ? 100 : 0)
                    await timeout(3000)
                    setSpinPopupActive(false);

                    setTriggerLeaderboardUpdate(_ => _ + 1);

                    let _betItems = setBetItemSpin(betItems, nextSpinRound, spunNumber);
                    setBetItems(_betItems)

                    updateSpinState(rounds, {}, { [nextSpinRound]: spunNumber })
                } else {
                    let { reason, refreshAuth } = res.data
                    if (refreshAuth) {
                        setNotificationTitle("Your signature has expired.")
                        setNotificationText("For security reasons we need you to sign a new signature. Please wait for your wallet signature to load.")
                        getUserAuth();
                    } else {
                        alert(reason);
                    }      
                }
            } catch (e) {
                console.log(e)
            }

            setDisabled(false)
        } catch (e) {
            console.log(e)
        }
    }

    const onAdminSpin = async () => {
        if (!isProviderExists(provider))
            return;
        if (provider.address.toLowerCase() !== gameAdmin)
            return;       

        try {
            let { spins } = gameDoc;
            let msg = createAdminSpinSignatureMessage(gameId, spins.length + 1);
            let sig = await provider.signer.signMessage(msg);

            setDisabled(true);

            let url = DATA.adminSpinUrl[NETWORK.TARGET_CHAIN_ID.toString()]
            try {
                await axios.get(url, 
                { params: {
                    gameId,
                    round: (spins.length + 1).toString(),
                    signature: sig
                }})
            } catch (e) {
                console.log(e)
                alert(e)
            }

            setDisabled(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handlePendingNextBet()
    }, [pendingNextBet])

    useEffect(() => {
        handlePendingSpins();
    }, [pendingSpinsTrigger])

    useEffect(() => {
        if (spinPopupActive) {
            if (document.querySelector("#winningSpin")) {
                document.querySelector("#winningSpin").play()
            }
        }
    }, [spinPopupActive])

    useEffect(() => {
        handlePendingBet();
    }, [pendingBet])

    useEffect(() => {
        autoSpin();
    }, [autoSpinTrigger, isAutoSpin])

    useEffect(() => {
        initialize();
        dynamicUpdate();
    }, [gameDoc, provider, authSignature])

    return (
        <>
            <div className={`game-content ${spinPopupActive ? 'bloor' : ''}`}>
                <audio id="spinAudio">
                    <source src="media/ding.mp3" type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
                <audio id="winningSpin">
                    <source src="media/win.mp3" type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
                <WinningPopup
                    active={spinPopupActive}
                    number={lastSpunNumber}
                    token={lastSpunReward}
                    round={lastSpunRound}
                />

                <div className="img" style={{ backgroundImage: `url("/img/game-bg.png")` }}>
                    {
                        spinnerItems.length > 0 ?
                            <Wheel items={spinnerItems} selectedItem={wheelItem} />
                        :
                            null
                    }
                </div>

                <div className="game-items">
                <Row style={{marginTop: "0px"}}>
                        <div className="item" style={{justifyContent: "right", background: "transparent", paddingTop: "0px"}}>
                            <div className="buttons">
                                <div className="inputs">
                                    <button
                                        className="spin"
                                        type="join"
                                        disabled={disabled}
                                        style={{marginRight: "10px"}}
                                        onClick={() => {
                                            if (!isObjectExists(gameDoc) || !isProviderExists(provider))
                                                return;

                                            let { isAdmin, extraRoundWallets, winner, deadline } = gameDoc

                                            if (!isNullOrUndefined(winner) && ethers.utils.isAddress(winner)) {
                                                setNotificationTitle("Spinning not available")
                                                setNotificationText("This game is finished.")
                                                setNotificationClosable(true)
                                                setIsNotification(true);
                                                return;
                                            }

                                            if (isAdmin) {
                                                setNotificationTitle("Spinning not available")
                                                setNotificationText("Only the admin can spin in this game.")
                                                setNotificationClosable(true)
                                                setIsNotification(true);
                                                return;
                                            }

                                            let isInExtraRound = extraRoundWallets.length > 0 && extraRoundWallets.includes(provider.address.toLowerCase())
                                            if (extraRoundWallets.length > 0 && !isInExtraRound) {
                                                setNotificationTitle("Spinning not available")
                                                setNotificationText("Only players in the extra round can spin now.")
                                                setNotificationClosable(true)
                                                setIsNotification(true);
                                                return;
                                            }

                                            if (now < startTime) {
                                                setNotificationTitle("Auto-spinning not available")
                                                setNotificationText("You can not spin now. Please return when the game is live. If you did not join Live Slottery, your bets will be spinned automatically and you can always check winning numbers and rank in finished games.")
                                                setNotificationClosable(true)
                                                setIsNotification(true);
                                                return
                                            }

                                            if (now >= deadline && !isInExtraRound) {
                                                setNotificationTitle("Auto-spinning not available")
                                                setNotificationText("You can not spin now. This game's deadline for betting and spinning has passed. If you did not join Live Slottery, your bets will be spinned automatically and you can always check winning numbers and rank in finished games.")
                                                setNotificationClosable(true)
                                                setIsNotification(true);
                                                return
                                            }

                                            setIsAutoSpinPopupActive(true);
                                        }}
                                    >
                                        {
                                            disabled ?
                                                <Oval
                                                    height="20"
                                                    width="40"
                                                    color='#ffffff'
                                                    ariaLabel='loading'
                                                />
                                            :
                                                <>Auto-Spin {isAutoSpin ? "(ON)" : "(OFF)" }</>
                                        }
                                    </button>
                                    <button
                                        className="spin"
                                        type="join"
                                        disabled={disabled}
                                        onClick={() => {
                                            if (now >= startTime) {
                                                if (!isObjectExists(gameDoc))
                                                    return;

                                                let { rounds, userBets, isAdmin, extraRoundWallets, winner, deadline } = gameDoc

                                                if (!isNullOrUndefined(winner) && ethers.utils.isAddress(winner)) {
                                                    setNotificationTitle("Spinning not available")
                                                    setNotificationText("This game is finished.")
                                                    setNotificationClosable(true)
                                                    setIsNotification(true);
                                                    return;
                                                }

                                                if (isAdmin && provider.address.toLowerCase() === gameAdmin) {
                                                    onAdminSpin();
                                                    return;
                                                }

                                                if (isAdmin) {
                                                    setNotificationTitle("Spinning not available")
                                                    setNotificationText("Only the admin can spin in this game.")
                                                    setNotificationClosable(true)
                                                    setIsNotification(true);
                                                    return;
                                                }

                                                let isInExtraRound = extraRoundWallets.length > 0 && extraRoundWallets.includes(provider.address.toLowerCase())
                                                if (extraRoundWallets.length > 0 && !isInExtraRound) {
                                                    setNotificationTitle("Spinning not available")
                                                    setNotificationText("Only players in the extra round can spin now.")
                                                    setNotificationClosable(true)
                                                    setIsNotification(true);
                                                    return;
                                                }

                                                if (now >= deadline && !isInExtraRound) {
                                                    setNotificationTitle("Spinning not available")
                                                    setNotificationText("You can not spin now. This game's deadline for betting and spinning has passed. If you did not join Live Slottery, your bets will be spinned automatically and you can always check winning numbers and rank in finished games.")
                                                    setNotificationClosable(true)
                                                    setIsNotification(true);
                                                    return
                                                }

                                                let nextSpinRound = getNextSpinRound(rounds)
                                                if (!nextSpinRound) {
                                                    let betsOfUser = userBets ? userBets[provider.address.toLowerCase()] : {}
                                                    if (betsOfUser && Object.keys(betsOfUser).length >= rounds) {
                                                        setNotificationTitle("Spinning not available")
                                                        setNotificationText("You have already spun all rounds.")
                                                        setNotificationClosable(true)
                                                        setIsNotification(true);
                                                    } else {
                                                        setNotificationTitle("Spinning not available")
                                                        setNotificationText("Please make your bet first.")
                                                        setNotificationClosable(true)
                                                        setIsNotification(true);
                                                    }
                                                } else {
                                                    onSpin();
                                                }
                                            } else {
                                                setNotificationTitle("Spinning not available")
                                                setNotificationText("You can not spin now. Please return when the game is live. If you did not join Live Slottery, your bets will be spinned automatically and you can always check winning numbers and rank in finished games.")
                                                setNotificationClosable(true)
                                                setIsNotification(true);
                                            }
                                        }}
                                    >
                                         {
                                            disabled ?
                                                <Oval
                                                    height="20"
                                                    width="40"
                                                    color='#ffffff'
                                                    ariaLabel='loading'
                                                />
                                            :
                                                <> Spin</>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Row>
                                        
                    <Row>
                        {
                            betItems.map((b, i) => {
                                return <BetItem
                                    round={b.round}
                                    maxSpinNum={maxSpinNum}
                                    placedBet={b.placedBet}
                                    spunNumber={b.spunNumber}
                                    onBet={val => {
                                        if (!isObjectExists(gameDoc) || !isProviderExists(provider))
                                            return;

                                        let { extraRoundWallets, winner, deadline } = gameDoc

                                        if (!isNullOrUndefined(winner) && ethers.utils.isAddress(winner)) {
                                            setNotificationTitle("Betting not available")
                                            setNotificationText("This game is finished.")
                                            setNotificationClosable(true)
                                            setIsNotification(true);
                                            return;
                                        }

                                        let isInExtraRound = extraRoundWallets.length > 0 && extraRoundWallets.includes(provider.address.toLowerCase())

                                        if (extraRoundWallets.length > 0 && !isInExtraRound) {
                                            setNotificationTitle("Betting not available")
                                            setNotificationText("Only players in the extra round can bet now.")
                                            setNotificationClosable(true)
                                            setIsNotification(true);
                                            return;
                                        }

                                        if (now >= deadline && !isInExtraRound) {
                                            setNotificationTitle("Betting not available")
                                            setNotificationText("You can not bet now. This game's deadline for betting and spinning has passed. Please try your luck in next game")
                                            setNotificationClosable(true)
                                            setIsNotification(true);
                                            return
                                        }

                                        onBet(val);
                                    }}
                                    loading={b.loading}
                                    disabled={b.disabled || disabled}
                                    key={i}
                                />
                            })
                        }
                    </Row>

                    <AutoSpinPopup
                            active={isAutoSpinPopupActive}
                            onClose={() => setIsAutoSpinPopupActive(false)}
                            onConfirm={() => setIsAutoSpin(_ => !_)}
                            isAutoSpin={isAutoSpin}
                        />
                </div>
            </div>
        </>
    )
}
