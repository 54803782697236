import React from 'react'
import './WiningPopup.scss'

const NotificationPopup = ({ active, title, text, onClose }) => {
  return (
    <div className={`popup-wrapper${active ? ' active' : ''}`}>
        <div className="popup-content">
        <div className="popup-body">
            <div className="head">
                <h4>{title}</h4>
                <span onClick={onClose}>&times;</span>         
            </div>
            <div className="content">
                <p style={{paddingBottom: "50px"}}>{text}</p>                    
            </div>  
        </div>
        </div>
    </div>
  )
}

export default NotificationPopup
