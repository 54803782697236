import React, { useEffect, useState }  from "react";
import Button from "../../../components/Buttons";

import { formatPrize, shouldShowPool } from "../../../utils";

export default function UpcomingGame({ 
    game, 
    now,
    handleGameClick
 }) {
    const getChosenGameStatus = () => {
        if (game) {
            let { startTime } = game;
            if (startTime < now) {
                return "Live"
            }
        }
    
        return "Upcoming"
    }
    
    const getChosenGameTitle = () => {
        if (game) {
            return "Slottery " + game.id
        }

        return "No upcoming games"
    }
    
      const getChosenGamePrize = () => {
        if (game) {
            return formatPrize(game.prize)
        }
    
        return "No upcoming games"
      }
    
        const getCountdown = () => {
            if (game) {
                let { startTime } = game;
                const difference = Math.max(startTime - now, 0);
                const sec = 1;
                const min = sec * 60;
                const hr = min * 60;
                const day = hr * 24;

                let textDay = Math.floor(difference / day);
                let textHour = Math.floor((difference % day) / hr);
                let textMin = Math.floor((difference % hr) / min);
                let textSec = Math.floor((difference % min) / sec);

                if (textDay < 10)
                    textDay = "0" + textDay;
                if (textHour < 10)
                    textHour = "0" + textHour;
                if (textMin < 10)
                    textMin = "0" + textMin;
                if (textSec < 10)
                    textSec = "0" + textSec;

                return textDay + ":" + textHour + ":" + textMin + ":" + textSec;
            }
    
            return "00:00:00:00"
        }

    return (
        <>
            <div className="tournament-info">
                 <div style={{fontSize: "20px", marginBottom: "20px"}}>{getChosenGameStatus()} Slottery</div>
                <div className="info-item">
                  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.1467 13.6667H23.3333V5.66667H15.3333V3.85333C15.3333 2.10667 14.08 0.520002 12.3467 0.346668C10.3467 0.146668 8.66666 1.70667 8.66666 3.66667V5.66667H0.67999V13.4H2.53332C4.27999 13.4 5.86666 14.5733 6.19999 16.28C6.63999 18.5733 4.89332 20.6 2.66666 20.6H0.666656V28.3333H8.39999V26.4667C8.39999 24.72 9.57332 23.1333 11.28 22.8C13.5733 22.36 15.6 24.1067 15.6 26.3333V28.3333H23.3333V20.3333H25.3333C27.2933 20.3333 28.8533 18.6533 28.6533 16.6533C28.48 14.92 26.88 13.6667 25.1467 13.6667Z" fill="#7ABF2B"/>
                  </svg>
                  <h4 className="first-title">{getChosenGameTitle()}</h4>
                </div>
                <div className="info-item">
                  <span className="sub-title">PRIZE</span>
                  <h4 className="second-title">{getChosenGamePrize()}</h4>
                  {
                    shouldShowPool(game?.prize) ?
                        <h5 style={{paddingTop: "10px"}}>Prize Pool: {game.pool} WATTs</h5>
                    :
                        null
                  }
                </div>
                {
                    getChosenGameStatus() !== "Live" ?
                    <div className="info-item">
                        <span className="sub-title">Time before beginning</span>
                        <h4 className="third-title">{getCountdown()}</h4>
                        <span className="clock">DHMS</span>
                    </div>
                    :
                    null
                }
                <div className="info-item">
                    {
                        game ?
                        <Button text="Join The Game" size="small" type="join" onClick={() => handleGameClick(game.id)}/>
                        :
                        <Button text="Unvailable" size="small" type="join" disabled/>
                    }

                </div>
              </div>
        </>
    )
}