import React from 'react'
import './ChatItem.scss'

const ChatItem = ({ avatar, username, message, isCurrent }) => (
    <>
        {
            isCurrent ?
            <div className="avatar" style={{justifyContent: "right"}}>
                <p><span>You</span> {message}</p>                             
            </div>
            :
            <div className="avatar">
                {/*<img src={avatar} alt=""/>*/}
                <p><span>{username}</span> {message}</p>
            </div>
        }
    </>
)

export default ChatItem
