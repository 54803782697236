import { useState, useEffect } from 'react';

import { 
    isProviderExists,
    isObjectExists
} from "../utils/index"
import { STORAGE } from "../config"

export default function useUserSettings() {
    const [userSettings, setUserSettings] = useState({});    

    function getUserSettings() {        
        const auth = localStorage.getItem(STORAGE.SETTINGS_KEY);

        if (auth) {
            setUserSettings(JSON.parse(auth))               
        }                      
    }

    function getUserSettingsOfAddress(address) {
        let userSettingsAtAddress = userSettings[address];
        if (isObjectExists(userSettingsAtAddress)) {
            return userSettingsAtAddress;
        }
        return {}
    }

    function getUserSettingsOfGame(address, gameId) {
        let userSettingsAtAddress = getUserSettingsOfAddress(address)
        if (isObjectExists(userSettingsAtAddress)) {
            let userSettingsAtGame = userSettingsAtAddress[gameId];
            if (isObjectExists(userSettingsAtGame)) {
                return userSettingsAtGame
            }
        }
        return {}
    }

    function updateUserSettingsSpecificValue(address, gameId, key, value) {
        let settingsCopy = JSON.parse(JSON.stringify(userSettings))

        let userSettingsOfAddress = getUserSettingsOfAddress(address);
        let userSettingsOfGame = getUserSettingsOfGame(address, gameId);

        if (!isObjectExists(userSettingsOfAddress))
            settingsCopy[address] = {}

        if (!isObjectExists(userSettingsOfGame))
            settingsCopy[address][gameId] = {}
        
        settingsCopy[address][gameId][key] = value;
        
        localStorage.setItem(STORAGE.SETTINGS_KEY, JSON.stringify(settingsCopy));
        setUserSettings(settingsCopy)
    }

    useEffect(() => {
        getUserSettings();
    }, [])

    return [
        userSettings,
        updateUserSettingsSpecificValue,
        getUserSettingsOfGame
    ]

}