import { ERRORS, NETWORK } from "../config";
import Contracts from "../contracts/hardhat_contracts.json";
import { ethers } from "ethers";

export const removeDecimals = (num) => {
    return ethers.utils.formatEther(num);
}

// Formats a string representing a number 
export const formatNumber = (str) => {
    if (!str || str === "" || typeof str !== "string")
        return "0";

    let commaSplit = str.includes(".") ? str.split(".") : str.split(",");
    let roundNum = commaSplit[0];

    // number between 0 and 999
    if (roundNum.length < 4) {         
        if (commaSplit.length > 1) {
            let commaPart = commaSplit[1].slice(0,2);
            return roundNum + "." + commaPart;
        } else {
            return roundNum;
        }
    } // 1000 - 999.999
    else if (roundNum.length >= 4 && roundNum.length < 7) {
        let hundredAmount = roundNum.substr(roundNum.length - 3);
        let thousandAmount = roundNum.slice(0, -3);

        if (parseInt(hundredAmount) > 9) {
            return thousandAmount + "." + hundredAmount.slice(0,2) + "K";
        } else {
            return thousandAmount + "K"
        }
    } // 1.000.000 - 999.999.999
    else if (roundNum.length >= 7 && roundNum.length < 10) {
        let thousandAmount = roundNum.substr(roundNum.length - 6);
        let milionAmount = roundNum.slice(0, -6);

        if (parseInt(thousandAmount) > 9999) {
            return milionAmount + "." + thousandAmount.slice(0,2) + "M";
        } else {
            return milionAmount + "M"
        }
    } // 1.000.000.000 - 999.999.999.999
    else if (roundNum.length >= 10 /*&& roundNum.length < 13*/) {
        let milionAmount = roundNum.substr(roundNum.length - 9);
        let bilionAmount = roundNum.slice(0, -9);

        if (parseInt(milionAmount) > 9999999) {
            return bilionAmount + "." + bilionAmount.slice(0,2) + "B";
        } else {
            return bilionAmount + "B"
        }
    }
}

// Formats number seconds to countdown string
export const formatSeconds = (secs) => {
    const sec = 1;
    const min = sec * 60;
    const hr = min * 60;
    const day = hr * 24;

    let textDay = Math.floor(secs / day);
    let textHour = Math.floor((secs % day) / hr);
    let textMin = Math.floor((secs % hr) / min);
    let textSec = Math.floor((secs % min) / sec);

    if (textDay < 10) {
        textDay = "0" + textDay;
    }

    if (textHour < 10) {
        textHour = "0" + textHour;
    }

    if (textMin < 10) {
        textMin = "0" + textMin;
    }

    if (textSec < 10) {
        textSec = "0" + textSec;
    }

    return "00:" + textMin + ":" + textSec
}

export const checkPositiveNumber = (num) => {
    let parsed = parseFloat(num);
    if (isNaN(parsed)) {
        return false;
    } else {
        return parsed >= 0
    }
}

export const getUnixTimeStamp = () => {
    return Math.floor(Date.now() / 1000);
}

export const getContractConfig = (chainId, contract) => {
  return Contracts[chainId][NETWORK.CHAIN_ID_TO_NAME[parseInt(chainId)]][
    "contracts"
  ][contract];
};

export const getErrorMessage = (e) => {
  let msg = `An unexpected error occured.`;
  for (const error of ERRORS.MESSAGES) {
    if (e.message.includes(error.error)) {
      msg = error.message;
    }
  }

  return msg;
};

export const regularNumberToWatts = num => {
    return ethers.BigNumber.from(10).pow(14).mul(Math.floor(num * 10000))
}

export const getTruncatedAddress = addr => {
    return addr.slice(0, 7) + "..." + addr.slice(-7)
}

export function monthToString(month) {
    if (month === 1) return "January";
    else if (month === 2) return "February";
    else if (month === 3) return "March";
    else if (month === 4) return "April";
    else if (month === 5) return "May";
    else if (month === 6) return "June";
    else if (month === 7) return "July";
    else if (month === 8) return "August";
    else if (month === 9) return "September";
    else if (month === 10) return "October";
    else if (month === 11) return "November";
    else if (month === 12) return "December";
  }

export function unixTimeStampToDate(timestamp) {
    let milisecs = timestamp * 1000;
    let date = new Date(milisecs);

    let minutes = date.getMinutes().toString();
    let hours = date.getHours().toString();
    let days = date.getDate();
    let month = date.getMonth();

    let isAm = parseInt(hours) < 12

    if (minutes.length === 1)
        minutes = "0" + minutes;
    if (hours.length === 1)
        hours = "0" + hours;

    return monthToString(month + 1) + " " + days + " " + hours + ":" + minutes + (isAm ? " AM" : " PM");
  }

export function isNullOrUndefined(val) {
    return val === undefined || val === null;
}

export function isObjectExists(val) {
    return !isNullOrUndefined(val) && Object.keys(val).length > 0
}

export function isArrayExists(val) {
    return !isNullOrUndefined(val) && val.length > 0
}

export function isProviderExists(provider) {
    return isObjectExists(provider) && !isNullOrUndefined(provider.address)
}

export const gameDocSpec = {
    collection: "games",
    fields: {
        userBets: "userBets", // { address: {1: 3, 4: 5} } (round : bet)
        userEntries: "userEntries", // { address: true, address: true }
        userPoints: "userPoints", // { address: 12 }
        userSpins: "userSpins", // { address: {1: 3, 4: 5}} (round: spin)
        isAdmin: "isAdmin", // bool
        prize: "prize", // string
        startTime: "startTime", // unix timestamp (seconds)
        deadline: "deadline", //unix timestamp (seconds)
        rounds: "rounds", // number
        spins: "spins", // [] (array of spins for each round)
        spinNum: "spinNum", // number (highest number on spinner)
        winner: "winner", // string (address of winner)
        entryPrice: "entryPrice", // string (bignumber of price in watts)
        extraRoundWallets: "extraRoundWallets", // array of strings
    }
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function createBetSignatureMessage(gameId, round, bet) {
    return "Slottery Bet: Game #" + gameId.toString() + " Round #" + round.toString() + " Bet #" + bet.toString()
}

export function createSpinSignatureMessage(gameId, round) {
    return "Slottery Spin: Game #" + gameId.toString() + " Round #" + round.toString()
}

export const validateDiscord = (discord) => {
    return String(discord)
      .toLowerCase()
      .match(/^.{3,32}#[0-9]{4}$/);
};

export function splitArrayIntoChunks(array, chunkSize) {
    let chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunks.push(chunk)
    }
    return chunks
}

// prizeObj shape:
//{
//    type: 'wattsPool' | 'custom',
//    percentages?: {
//        '1': 10,
//        '2': 20,
//        '3': 30
//    },
//    value: '300$'
//}
export function formatPrize(prizeObj) {
    if (!prizeObj)
        return "";
    if (Object.keys(prizeObj) === 0)
        return "";
    if (!prizeObj.type)
        return "";
    switch(prizeObj.type) {
        case "wattsPool":
            let { percentages } = prizeObj;
            if (!percentages)
                return "";
            if (Object.keys(percentages) === 0)
                return "";
            return Object.keys(percentages).map(position => percentages[position] + "%").join("|");
        case "custom":
            let { value } = prizeObj;
            if (!value)
                return "";
            return value;
        default:
            return "";
    }
}

export function shouldShowPool(prizeObj) {
    if (!prizeObj)
        return false;
    if (Object.keys(prizeObj) === 0)
        return false;
    if (!prizeObj.type)
        return false;
    switch(prizeObj.type) {
        case "wattsPool":
            return true;
        case "custom":
            return false;
        default:
            return false;
    }
}