import React, { useState, useEffect } from "react"
import { ethers } from "ethers"

import {
    collection,
    getDocs,
    where,
    query,
} from 'firebase/firestore';

import { Table } from 'react-bootstrap'

import {
    getTruncatedAddress,
    splitArrayIntoChunks
} from "../../../utils"

import useChangeableObject from "../../../hooks/useChangeableObject";

export default function LeaderBoard({ gameDoc, firestore, triggerUpdate }) {

    const [leaderBoardObject, setLeaderBoardObject] = useState([]);

    const [canLeaderboardUpdate] = useChangeableObject(gameDoc?.userPoints)

    async function loadLeaderboard() {       
        if (gameDoc) {            
            let { userPoints } = gameDoc           
            if (userPoints && Object.keys(userPoints).length > 0) {
                //if (!canLeaderboardUpdate(userPoints))
                //    return;

                let userToPoints = Object.keys(userPoints).map(wallet => [wallet, userPoints[wallet]]);
                userToPoints.sort((a,b) => b[1] - a[1]);
                userToPoints = userToPoints.slice(0,25)

                let wallets = userToPoints.map(u => u[0].toLowerCase())
                wallets = splitArrayIntoChunks(wallets, 10);

                let addressToDiscord = {}

                for (let chunk of wallets) {
                    let q = query(collection(firestore, "wallets"), where("address" , "in", chunk));

                    let querySnapShot = await getDocs(q)

                    let _walletData = querySnapShot.docs.map((doc) => {
                        return doc.data()
                    })
                    _walletData.map(d => addressToDiscord[d.address] = d.discord)
                }

                userToPoints = userToPoints.map(u => {
                    let disc = addressToDiscord[u[0]]
                    if (disc && disc !== "") {
                        return [disc, u[1]]
                    }

                    return u
                });

                setLeaderBoardObject(userToPoints)
            }
        }
    }

    useEffect(() => {
        loadLeaderboard();
    }, [triggerUpdate]);

    return (
        <>
            <div className="table-container">
                <Table responsiv="lg" className="sl-table join-tb">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>User</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        leaderBoardObject.map((lb, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{ethers.utils.isAddress(lb[0]) ? getTruncatedAddress(lb[0]) : lb[0]}</td>
                                    <td>{lb[1]}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </Table>
            </div>
        </>
    )
}
