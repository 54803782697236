import React, { useState } from 'react'
import axios from "axios"

import Button from "../Buttons";
import './popup.scss'

import {
    DATA,
    NETWORK
} from "../../config"


const DiscordAuth = ({ 
    active = false, 
    onClose, 
    provider
}) => {
    const [error, setError] = useState();

    function handleDiscordAuthentication()  {
        window.open(DATA.discordSubmitWalletUrl[NETWORK.TARGET_CHAIN_ID.toString()], "_self")
    }    


  return (
    <div className={`popup-wrapper${active ? ' active' : ''}`}>
      <div className="popup-content">
        <div className="popup-body">
          <div className="head">
            <h4>Discord Login</h4>
          </div>
            {
                <div className="content">                  
                    <p style={{padding:"30px", paddingBottom: "0px"}}>                    
                        {
                            error ?
                                error
                            :
                                <span>Connect your discord account and get additional points from the mee6 system on each game you play!
                                You can not continue without connecting your discord.</span>
                        }
                    </p>
                    <hr/>
                                   
                    <div className="buttons">                       
                        <Button text="Log in to Discord" size="small" type="primary" block onClick={handleDiscordAuthentication}/>
                    </div>
                </div>
            }          
        </div>
      </div>
    </div>
  )
}

export default DiscordAuth
