import React from "react";
import Header from "../../components/header/Header";
import { Container } from 'react-bootstrap'
import './terms.scss'

const Index = () => (
  <>
    <Header fixed="none" />
    <section style={{ backgroundImage: `url("img/contact_bg.png")` }} className="terms-section">
      <Container>
        <div className="content">
          <h2>Privacy Policy for Followerzbuzz</h2>
          <p>At Followerzbuzz.com, accessible from https://www.followerzbuzz.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Followerzbuzz.com and how we use it.</p>
          <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
          <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Followerzbuzz.com. This policy is not applicable to any information collected offline or via channels other than this website.</p>
          <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
          <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
          <p><p>Like any other website, Followerzbuzz.com uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p></p>
          <p>For more general information on cookies, please read "What Are Cookies".</p>
          <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
          <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
          <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
          <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
          <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
          <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
          <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
          <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
        </div>
      </Container>
    </section>
  </>
)

export default Index
