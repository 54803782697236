import React, { useState } from "react";
import Home from "./Home/Index";
import Game from "./Game/index";
import Terms from "./Terms/index";
import Upcoming from "./Upcoming/index";

export default function Index () {

    return (
       <div>
           <Home />
       </div>
    )
}
