import React, { useRef, useEffect } from 'react'

import Header from "../../components/header/Header";
import Main from "./Main";
import HowToPlay from "./HowToPlay";
import Leaderboard from "./Leaderboard";
import Faq from "./Faq";
import Contact from "./Contact";

const Home = () => {
  const mainRef = useRef(null)
  const howToPlayRef = useRef(null)
  const faqRef = useRef(null)
  const contactRef = useRef(null)

  const executeToMain = () => {
    window.location.hash = '#main'
    return mainRef.current.scrollIntoView()
  }
  const executeToHowToPlay = () => {
    window.location.hash = '#howToPlay'
    return howToPlayRef.current.scrollIntoView()
  }
  const executeFaq = () => {
    window.location.hash = '#faq'
    return faqRef.current.scrollIntoView()
  }
  const executeContact = () => {
    window.location.hash = '#contact'
    return contactRef.current.scrollIntoView()
  }

  const props = {
    main: executeToMain,
    howToPlay: executeToHowToPlay,
    faq: executeFaq,
    contact: executeContact
  }

  useEffect(() => {
    if (window.location.hash) {
      const param = window.location.hash.replace('#', '')
      props[param]()
    }
  }, [])

  return (
    <>
      <Header {...props} fixed="top" scrollRef={mainRef}/>
      <Main scrollRef={mainRef} goToScroll={executeToHowToPlay} goToPlay={executeToHowToPlay}/>
      <HowToPlay scrollRef={howToPlayRef}/>
      <Leaderboard/>
      <Faq scrollRef={faqRef}/>
      <Contact scrollRef={contactRef} goToScroll={executeToMain}/>
    </>
  )
}

export default Home
