import React from 'react'
import './WiningPopup.scss'

const WinningPopup = ({ active, round, token, number }) => {
  return (
    <div className={`winning-popup-wrapper${active ? ' active' : ''}`}>
      <div className="popup-content">
        <div style={{ marginRight: 20 }}>
          <span>Winning number of round {round} is</span>
          <h2>{number}</h2>
        </div>
        <div className="btn">
          <span>You got</span>
          <span><b>{token}</b> points</span>
        </div>
      </div>
    </div>
  )
}

export default WinningPopup
