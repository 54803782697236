export default class Slottery {
    constructor(instance, signer) {
        this.instance = instance.connect(signer);
    }

    slotteryGames(id) {
        return this.instance.slotteryGames(id);
    }

    userToGameEntries(address, id) {
        return this.instance.userToGameEntries(address, id)
    }

    enterGame(id) {
        return this.instance.EnterGame(id);
    }

    getPrizePoolForGames(games) {
        return this.instance.getPrizePoolForGames(games)
    }

    slotteryGameToPrizePool(game) {
        return this.instance.slotteryGameToPrizePool(game);
    }
}