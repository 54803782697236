import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { getContractConfig } from "../utils";
import { CONTRACTS, NETWORK } from "../config";

export default function useContracts(provider, key) {
  const [wrongNetwork, setWrongNetwork] = useState();
  const [contractSlotie, setContractSlotie] = useState(null);  
  const [contractWatts, setContractWatts] = useState(null);
  const [contractSlottery, setContractSlottery] = useState(null);

  useEffect(() => {
    if (provider) {
      (async function () {
        const contractData = {};
        let isCorrectChainId = provider.chainId === NETWORK.TARGET_CHAIN_ID;

        if (isCorrectChainId) {
          Object.keys(CONTRACTS).forEach((item) => {
            const config = getContractConfig(
              provider.chainId.toString(),
              CONTRACTS[item]
            );
            if (config) {
              contractData[CONTRACTS[item]] = new ethers.Contract(
                config.address,
                config.abi,
                provider.instance
              );
            }
          });
        } else {
            alert(`Please connect yout wallet to the ${NETWORK.CHAIN_ID_TO_NAME[NETWORK.TARGET_CHAIN_ID]} network`)
            setWrongNetwork(NETWORK.CHAIN_ID_TO_NAME[provider.chainId])
        }

        //setContractSlotie(contractData[CONTRACTS.SLOTIE]);
        setContractSlotie({});
        setContractWatts(contractData[CONTRACTS.WATTS]);
        setContractSlottery(contractData[CONTRACTS.SLOTTERY]);
      })();
    }
  }, [provider, key]);

  return [
    wrongNetwork,
    contractSlotie,
    contractWatts,
    contractSlottery    
  ];
}
