import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import './header.scss'
import ConnectWallet from "../ConnectWallet";

import { formatPrize, shouldShowPool } from '../../utils';

const GameHeader = ({ gameId, prize, pool, status, winner }) => {
  
  return (
    <Navbar bg="dark" expand="lg" className="navigation-bar"  style={{ borderColor: '#79BE2B' }}>
      <Container>
        <Navbar.Brand href="https://slottery.slotie.com"><img src="img/logo_white.svg" loading="lazy" width="35" alt="" className="logo slotie-nav-logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            {
                gameId ?
                <Nav className="justify-content-center ms-auto mt-sm-5 mt-lg-0">
                    <a className={`nav-link`}><strong>Game:</strong> #{gameId}</a>
                    {
                        prize ?
                        <a className={`nav-link`}><strong>Prize:</strong> {formatPrize(prize)}</a>
                        :
                        <></>
                    }
                    {
                        pool && shouldShowPool(prize) ?
                        <a className={`nav-link`}><strong>Pool:</strong> {pool} WATTs</a>
                        :
                        <></>
                    }
                    {
                        status ?
                        <a className={`nav-link`}><strong>Status:</strong> {status}</a>
                        :
                        <></>
                    }
                    {
                        winner ?
                        <a className={`nav-link`}><strong>Winner:</strong> {winner}</a>
                        :
                        <></>
                    }
                </Nav>
                :
                <></>
            }
          <Nav className="justify-content-center ms-auto mt-sm-5 mt-lg-0">            
            <div className="d-flex ms-3">
              <ConnectWallet />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default GameHeader
