import React, { useState, useEffect} from 'react'
import { ethers } from 'ethers';

import Button from "../Buttons";
import Loader from "../Loader";
import './popup.scss'
import { Oval } from  'react-loader-spinner'

import Watts from "../../adapters/watts";
import Slottery from "../../adapters/slottery"

import useContracts from "../../hooks/useContracts";

import { 
    isProviderExists,
    isObjectExists
} from "../../utils/index"

import { getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';

const PayPopup = ({ 
    active = false, 
    onClose, 
    provider,
    firestore,
    chosenGame,
    onPayDone,
    isGameFree
}) => {
    const [
        ,
        ,
        contractWatts,
        contractSlottery
    ] = useContracts(provider)


    useEffect(() => {
        if (isGameFree && active)
            onPayDone();
    }, [active])

    const [error, setError] = React.useState();
    const [txHash, setTxHash] = React.useState(undefined);
    const [isTx, setIsTx] = React.useState(undefined);


    async function onPay() {
        setError(undefined)
        setIsTx(true)
        if (contractWatts && contractSlottery && isObjectExists(chosenGame)) {
            let wattsObj = new Watts(contractWatts, provider.signer);
            let slotteryObj = new Slottery(contractSlottery, provider.signer);

            let game = await slotteryObj.slotteryGames(chosenGame.id);
            let now = Math.floor(Date.now() / 1000)

            if (now > game.deadline) {
                setError("The deadline for this game has passed and the game is finished, please wait for next game");
                return;
            }

            let wattsBalance = await wattsObj.balanceOf(provider.address);
            if (wattsBalance.lt(chosenGame.entryPrice)) {
                setError("Watts balance too low")
                return;
            }

            try {
                let tx = await slotteryObj.enterGame(chosenGame.id)
                setTxHash(tx.hash)
                await tx.wait();
                onPayDone(undefined, true)               
            } catch(e) {
                setError("An unexpected error occured. Please refresh the page and try again.");
                console.log(e)
            }
        }
        setIsTx(false)
    }

    const Loading = () => (
        <>
        {
            isTx && !error &&
            <>
                <div style={{paddingBottom: "30px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loader />
                  <div>Transaction is loading...</div>
                </div>
                {
                    txHash &&

                    <>
                        <p>
                            <a href={"https://bscscan.com/tx/" + txHash} target="_blank">
                                <span className="text bold centred">View transaction on Bscscan.</span>
                            </a>
                        </p>
                    </>
                }
            </>
        }
        </>
    )

  return (
    <div className={`popup-wrapper${active ? ' active' : ''}`}>
      <div className="popup-content">
        <div className="popup-body">
          <div className="head">
            <h4>Payment</h4>
            <span onClick={onClose}>&times;</span>
          </div>
            {
                <div className="content">
                    <p>You pay the game fee!</p>
                    <div className="input">
                        <input type="text" value={chosenGame && chosenGame.entryPrice ? ethers.utils.formatEther(chosenGame.entryPrice) + " WATTS" : null} disabled />
                    </div>
                    <hr/>
                    <Loading />                    
                    {
                        !isTx ?
                        <div className="buttons">
                            <Button text="Close" size="small" type="dark" block style={{ marginRight: 15 }} onClick={onClose} />
                            <Button text="Pay now" size="small" type="primary" block onClick={onPay}/>
                        </div>
                        :
                        <></>
                    }
                    {
                        error &&
                        <p style={{paddingBottom: "10px", color:"red"}}>{error}</p>
                    }
                </div>
            }          
        </div>
      </div>
    </div>
  )
}

export default PayPopup
