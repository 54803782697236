import React, { useEffect, useState }  from "react";
import { useNavigate } from 'react-router';
import { ethers } from "ethers";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap'

import Header from "../../components/header/Header";
import PayPopup from "../../components/PayPopup";
import NotificationPopup from "../../components/NotificationPopup";
import DiscordAuth from "../../components/DiscordAuth";
import UpcomingGame from "./upcomingGame";
import GamesList from "./gamesList";

import './upcoming.scss'

import { collection, doc, getDoc } from 'firebase/firestore';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { httpsCallable } from 'firebase/functions';

import { getFireBase } from "../../hooks/useFirebase";
import ProviderHOC from "../../hoc/provider";
import useContracts from "../../hooks/useContracts";
import Slottery from "../../adapters/slottery"
import useClock from "../../hooks/useClock";
import {
    isObjectExists,
    isArrayExists,
    isProviderExists,
    isNullOrUndefined
} from "../../utils/index"

const [firestore, functions] = getFireBase()

const Index = ({ provider }) => {
    let navigate = useNavigate();
    const [hide, setHide] = useState(false)


    const [
        ,
        ,
        ,
        contractSlottery
    ] = useContracts(provider)

    const [
        slottery_games_value,
        ,
        slottery_games_error
    ] = useCollection(collection(firestore, "slottery_games"), { snapshotListenOptions: { includeMetadataChanges: true } });
    const [
        upcoming_game_value,
        ,
        upcoming_game_doc_error
    ] = useDocument(doc(firestore, "slottery_upcoming", "main"), {snapshotListenOptions: {includeMetadataChanges: true}});

    const [chosenGame, setChosenGame] = useState();
    const [upcomingGame, setUpcomingGame] = useState()
    const [upcomingGames, setUpcomingGames] = useState([]);
    const [finishedGames, setFinishedGames] = useState([]);
    const [userPaidGames, setUserPaidGames] = useState({}); // object with key: gameId, value: true if paid

    const [isGameFree, setIsGameFree] = useState(false)

    const [payPopupActive, setPayPopupActive] = useState(false);
    const [discordPopupActive, setDiscordPopupActive] = useState(false);
    const [now, setNow] = useState(Math.floor(Date.now() / 1000));
    useClock(() => setNow(n => Math.floor(Date.now() / 1000)));

    const [loadingNotifActive, setLoadingNotificationActive] = useState(false);
    const [loadingNotifTrigger, setLoadingNotifTrigger] = useState(0)
    const loadingNotifTitle = "Loading Slottery Games"
    const loadingNotifText = "Please wait patiently for the slottery games to be loaded. This may take a couple of minutes."

    const [gamesLastUpdated, setGamesLastUpdated] = useState(0)

    async function initializeGames(games, error) {
        let nextUpdate = 0//gamesLastUpdated + 20
        if (
            isObjectExists(games) && 
            !error && now >= nextUpdate && 
            isProviderExists(provider) && 
            contractSlottery
        ) {
            setGamesLastUpdated(now)

            let gameData = games.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            gameData.sort((a,b) => a.startTime - b.startTime)
            let slotteryObj = new Slottery(
                contractSlottery, provider.signer
            )

            let prizePools = await slotteryObj.getPrizePoolForGames(gameData.map(g => g.id));
            gameData.forEach((game, id) => {
                let prizePoolFormattedString = ethers.utils.formatEther(prizePools[id]);
                let prizePoolSliceAmount = prizePoolFormattedString.split(".")[0].length + 3
                gameData[id].pool = ethers.utils.formatEther(prizePools[id]).slice(0, prizePoolSliceAmount);
            })           

            let upcomingGames = gameData.filter(g => !ethers.utils.isAddress(g.winner) && now <= g.deadline);
            let finishedGames = gameData.filter(g => ethers.utils.isAddress(g.winner) || now > g.deadline);

            finishedGames.sort((a,b) => b.deadline - a.deadline);
            
            setUpcomingGame(upcomingGames[0])

            setUpcomingGames(upcomingGames)
            setFinishedGames(finishedGames)
        }
    }

    function initializeUpcomingGame(_upcomingGame, error, _upcomingGames, _finishedGames) {
    }

    function getDiscordCodeFromUrl() {
        let url = window.location.href;
        let splitByCode = url.split("code=")
        if (splitByCode.length < 2)
            return undefined;
        else if (splitByCode.length === 2)
            return splitByCode[1];
        else {
            let codeSection = splitByCode[1];
            let codeSectionSplit = codeSection.split("&");
            if (codeSectionSplit.length > 1)
                return codeSectionSplit[0];
            else
                throw new Error("Invalid slottery URL");
        }
    }

    async function checkUserDiscord() {
        if (
            isProviderExists(provider) &&
            !isNullOrUndefined(provider.address)
        ) {
            const docRef = doc(firestore, "wallets", provider.address.toLowerCase());
            let userWalletData = await getDoc(docRef);
            let userData;
            if (userWalletData.exists()) {
                userData = userWalletData.data()
            }

            if (!userWalletData.exists() || !userData.officialLoginDone) {
                let discordAuthCode = getDiscordCodeFromUrl();
                if (discordAuthCode) {
                    const discordAuthApi = httpsCallable(functions, 'discordAuth');
                    try {
                        let res = await discordAuthApi({
                            code: discordAuthCode,                           
                            address: provider.address
                        });
        
                        if (res.data.success) {
                            userWalletData = await getDoc(docRef);
                            setLoadingNotifTrigger(_ => _ + 1)
                        }
                    } catch(e) {
                        console.log(e)
                    }
                } else {
                    setDiscordPopupActive(true);
                }
            } else
                setLoadingNotifTrigger(_ => _ + 1)
        }
    }

    async function getUserPaidGames(_upcomingGames, _finishedGames) {       
        if (
            isProviderExists(provider) &&
            (isArrayExists(_upcomingGames) ||
            isArrayExists(_finishedGames)) &&
            contractSlottery
        ) {            
            let games = _upcomingGames.concat(_finishedGames)
            let userPaid = {}
            for (let i = 0; i < games.length; i++) {
                let game = games[i];
                let { userEntries, isFree } = game;

                if (isFree) {
                   userPaid[game.id] = true;
                   continue;
                }

                if (userEntries) {
                    let paid = userEntries[provider.address.toLowerCase()] || false
                    if (!paid) {
                        let slotteryObj = new Slottery(contractSlottery, provider.signer);
                        paid = await slotteryObj.userToGameEntries(provider.address, game.id)
                    }
                    userPaid[game.id] = paid;
                }
            }

            setUserPaidGames(userPaid)           
            setLoadingNotifTrigger(_ => _ + 1)
        }
    }

    function handleGameClick(id) {
        if (!provider || !provider.address) {
            alert("Please connect your wallet to enter a game")
            return;
        }

        if (Object.keys(userPaidGames).length === 0) {
            return;
        }

        let games = upcomingGames.concat(finishedGames)

        if (!games.find(g => parseInt(g.id) === parseInt(id))) {
          alert("Game selected does not exist")
          return;
        }

        let game = games.find(g => parseInt(g.id) === parseInt(id));
        let { isFree } = game
        setIsGameFree(isFree)

        if (!isNullOrUndefined(game.winner) && ethers.utils.isAddress(game.winner)) {
            navigate("/game", { state: { gameId: id } })
        }

        if (userPaidGames[id]) {
            navigate("/game", { state: { gameId: id } })
        } else {
              //let { deadline, winner, extraRoundWallets } = game

              //if (winner !== null && winner !== undefined && ethers.utils.isAddress(winner)) {
              //    alert("Game already finished and a winner was already chosen.")
              //    return;
              //}

              //if (extraRoundWallets && extraRoundWallets.length > 0 && !extraRoundWallets.includes(provider.address.toLowerCase())) {
              //    alert("Game already at final stage. You can not enter anymore.")
              //}

            if (isFree) {
                setIsGameFree(true)
            }

            setChosenGame(game)
            setPayPopupActive(true);
        }
    }

    useEffect(() => {
        initializeGames(slottery_games_value, slottery_games_error)
    }, [slottery_games_value, slottery_games_error, contractSlottery, provider]);
    
    useEffect(() => {
        initializeUpcomingGame(upcoming_game_value, upcoming_game_doc_error, upcomingGames, finishedGames)
    }, [upcoming_game_value, upcoming_game_doc_error, upcomingGames, finishedGames])

    useEffect(() => {
        getUserPaidGames(upcomingGames, finishedGames)
    }, [provider, upcomingGames, finishedGames, contractSlottery])

    useEffect(() => {
        checkUserDiscord()
    }, [provider])

    useEffect(() => {
        if (loadingNotifTrigger > 0)
            setLoadingNotificationActive(Object.keys(userPaidGames).length === 0)        
    }, [loadingNotifTrigger])

    const hideHint = (e) => {
        setHide(true)
        console.log('Set hide', e)
        // localStorage.setItem('scroll_hint', true);
    }

    // useEffect(() => {
    //     if (localStorage.getItem('scroll_hint')) {
    //         setHide(true)
    //     }
    // }, [])

  return (
    <>
      <PayPopup
        active={payPopupActive}
        onClose={() => setPayPopupActive(false)}
        provider={provider}
        firestore={firestore}
        chosenGame={chosenGame}
        onPayDone={() =>  navigate("/game", { state: { gameId: chosenGame.id } })}
        isGameFree={isGameFree}
      />
      <DiscordAuth
        active={discordPopupActive}
        onClose={() => setDiscordPopupActive(false)}
        provider={provider}
      />
       <NotificationPopup
            active={loadingNotifActive}
            title={loadingNotifTitle}
            text={loadingNotifText}
            onClose={() => {}}
        />
      <Header fixed="none" />
      <section style={{ backgroundImage: `url("img/contact_bg.png")` }} className="upcoming-section">
        <Container fluid>
          <Row>
            <Col lg={3}>
              <UpcomingGame
                game={upcomingGame}
                now={now}
                handleGameClick={handleGameClick}
              />
            </Col>
            <Col lg={9}>
              <div className="tournament-list-bg" onScroll={hideHint}>
                {!hide ? (
                  <div id="scroll-hint">
                      <svg width="70px" height="70px" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
                          <g fill="#ccc">
                              <path d="m581.32 365.16c-0.15625-14.789-11.254-26.773-24.863-26.773-2.9258 0-5.6914 0.65234-8.3008 1.6719-0.46484-13.121-11.355-23.68-24.773-23.68-4.1562 0-8.0234 1.1094-11.469 2.8984-4.2578-7.4922-12.293-12.625-21.605-12.625-2.8945 0-5.625 0.58594-8.2109 1.4805v-37.863c0-13.52-11.148-24.523-24.863-24.523-13.711 0-24.863 11-24.863 24.523v101.02c-14.168 0.66797-25.324 8.6367-28.48 21.492-4.1562 16.895-2.793 35.969 4.0625 56.68 5.1523 15.586 17.141 31.258 36.645 47.898 1.5664 1.3359 3.4922 1.9922 5.3984 1.9922 2.3555 0 4.6914-0.98828 6.3359-2.9219 2.9883-3.4961 2.5664-8.75-0.92188-11.73-16.883-14.414-27.535-28.027-31.66-40.469-5.9336-17.938-7.1406-33.473-3.6953-47.473 1.4062-5.7227 6.5352-8.3555 12.312-8.8477v14.938c0 4.5977 3.7305 8.3242 8.3242 8.3242 4.5977 0 8.3242-3.7266 8.3242-8.3242l0.007812-132.58c0-4.3438 3.6836-7.875 8.2109-7.875 4.5312 0 8.2148 3.5312 8.2148 7.875v94.172c0 4.5977 3.7266 8.3242 8.3203 8.3242 4.5977 0 8.3242-3.7266 8.3242-8.3242v-33.266c0-4.3477 3.6836-7.8789 8.2109-7.8789 4.5273 0 8.2109 3.5312 8.2109 7.8789v37.816c0 4.5938 3.7266 8.3203 8.3242 8.3203 4.5938 0 8.3203-3.7266 8.3203-8.3203v-28.094c0-4.3438 3.6836-7.875 8.2109-7.875 4.5273 0 8.2109 3.5312 8.2109 7.875v35.605c0 4.5977 3.7266 8.3242 8.3242 8.3242 4.5938 0 8.3203-3.7266 8.3203-8.3242l0.011719-11.023c0-5.6641 3.7656-10.441 8.2148-10.441 4.4531 0 8.2109 4.7773 8.2109 10.441 0 0.20312 0.003906 0.40625 0.023438 0.60938 0.058594 0.71094 4.9531 71.402-17.953 121.48-1.9141 4.1836-0.070313 9.1211 4.1055 11.031 1.1211 0.51172 2.2969 0.75391 3.457 0.75391 3.1602 0 6.1758-1.8047 7.5781-4.8594 24.148-52.766 19.855-123.45 19.445-129.34z"/>
                              <path d="m397.87 285.37c0.070312-0.16406 0.097656-0.33984 0.15625-0.51172 0.11719-0.33984 0.24609-0.68359 0.31641-1.043 0.10547-0.53516 0.16406-1.0859 0.16406-1.6445 0-0.55859-0.0625-1.1094-0.16406-1.6484-0.070312-0.32812-0.18359-0.63281-0.28906-0.94531-0.070313-0.20312-0.10938-0.41016-0.1875-0.60938-0.14844-0.34766-0.33984-0.66406-0.52734-0.98438-0.089844-0.14844-0.14844-0.30469-0.24219-0.44141-0.30469-0.44922-0.64844-0.87109-1.0312-1.2539l-18.508-18.5c-3.2539-3.2539-8.5195-3.2539-11.77 0s-3.2539 8.5234 0 11.77l4.2891 4.2891h-170.41c-4.5938 0-8.3203 3.7266-8.3203 8.3203 0 4.5977 3.7266 8.3242 8.3203 8.3242h170.42l-4.3008 4.3047c-3.2539 3.2539-3.2539 8.5195 0 11.77 1.625 1.625 3.7539 2.4375 5.8828 2.4375 2.1328 0 4.2578-0.80859 5.8867-2.4375l18.508-18.512c0.38281-0.38281 0.72266-0.80078 1.0312-1.2539 0.085938-0.125 0.13281-0.26172 0.21484-0.38672 0.19922-0.33594 0.40234-0.67969 0.55469-1.043z"/>
                              <path d="m371.68 323.26h-170.42l4.3008-4.3047c3.2539-3.2539 3.2539-8.5195 0-11.77s-8.5195-3.2539-11.77 0l-18.512 18.512c-0.37891 0.38281-0.72266 0.80469-1.0273 1.2539-0.089844 0.12891-0.14062 0.26953-0.22266 0.40234-0.19531 0.33203-0.39844 0.66797-0.54297 1.0273-0.074219 0.17188-0.10547 0.35156-0.16016 0.52734-0.11719 0.33594-0.24609 0.67188-0.3125 1.0273-0.10938 0.53906-0.16406 1.0898-0.16406 1.6484 0 0.55859 0.058594 1.1094 0.16406 1.6484 0.066406 0.31641 0.17969 0.60938 0.28125 0.91406 0.070312 0.21484 0.11328 0.42969 0.19922 0.64062 0.13672 0.33203 0.32031 0.63281 0.50391 0.94141 0.09375 0.16016 0.16016 0.33203 0.26562 0.48828 0.29688 0.44922 0.64844 0.87109 1.0273 1.25l18.512 18.504c1.625 1.625 3.7539 2.4375 5.8828 2.4375 2.1328 0 4.2578-0.80859 5.8867-2.4375 3.2539-3.2539 3.2539-8.5234 0-11.77l-4.2969-4.2969h170.41c4.5977 0 8.3242-3.7266 8.3242-8.3203-0.003907-4.5898-3.7344-8.3242-8.3359-8.3242z"/>
                          </g>
                      </svg>

                  </div>
                ) : null}
                <div className="tournament-list">
                  <Tabs defaultActiveKey="upcoming" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="upcoming" title="Upcoming">                        
                        <GamesList
                            games={upcomingGames}
                            handleGameClick={handleGameClick}
                            showWarning={!isProviderExists(provider)}
                        />
                    </Tab>
                    <Tab eventKey="finished" title="Finished">                           
                        <GamesList
                            games={finishedGames}
                            handleGameClick={handleGameClick}
                            showWarning={!isProviderExists(provider)}
                        />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="bg"><img src="img/upcoming_bg.png" alt=""/></div>
      </section>
    </>
  )
}

export default ProviderHOC(Index)
