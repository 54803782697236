import React, { memo, useEffect } from "react"

import ProviderHOC from "../../hoc/provider";
import useConnect from "../../hooks/useConnect";

import Button from "../Buttons";
import { getTruncatedAddress } from "../../utils";

const ConnectWallet = memo(
    ({ provider, setProvider }) => {
        const isConnected = !!provider?.address;
        const [, isConnecting, doConnect, doDisconnect, connection] = useConnect(provider);
    
        useEffect(() => {
          setProvider(connection);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [connection]);
        
        return (
            <>
                {
                    !isConnected ?
                    <>
                        <Button text="Connect Your Wallet" type="connect" onClick={doConnect} />
                    </>
                    :
                    <>
                        <Button text={"Disconnect"} type="connect" onClick={doDisconnect} />
                    </>
                }
            </>
        )
    }
)

export default ProviderHOC(ConnectWallet);