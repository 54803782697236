import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Table } from 'react-bootstrap'
import { ethers } from "ethers"

import GameHeader from "../../components/gameHeader/GameHeader";
import WinningPopup from "../../components/WiningPopup";
import NotificationPopup from "../../components/NotificationPopup";
import PayPopup from "../../components/PayPopup";
import GamePanel from "../../components/GamePanel";
import Spinner from "../../components/Spinner";
import ConnectWallet from "../../components/ConnectWallet";
import Wheel from "../../components/Wheel";
import ChatBox from "./chatBox";
import LeaderBoard from "./leaderBoard";
import GameCore from "./gameCore";
import './game.scss'

import { useDocument } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';

import useContracts from "../../hooks/useContracts";
import useWeb3FirebaseAuth from "../../hooks/useWeb3FirebaseAuth";
import { getFireBase } from "../../hooks/useFirebase";

import Slottery from "../../adapters/slottery"
import ProviderHOC from "../../hoc/provider";
import useClock from "../../hooks/useClock";
import {
    gameDocSpec,
    isNullOrUndefined,
    unixTimeStampToDate,
    timeout,
    validateDiscord,
    isObjectExists,
    isArrayExists,
    isProviderExists
} from "../../utils"

const [firestore, functions] = getFireBase()

const Index = ({ provider }) => {
    const { state } = useLocation();
    const { gameId } = state;

    const [
        ,
        ,
        ,
        contractSlottery
    ] = useContracts(provider)

    const [
        firebase_game_doc,
        ,
        firebase_game_doc_error
    ] = useDocument(doc(firestore, "slottery_games", gameId), {snapshotListenOptions: {includeMetadataChanges: true}});

    const [
        firebase_operations_doc,
        ,
        firebase_operations_error
    ] = useDocument(doc(firestore, "slottery_operations", "main"), {snapshotListenOptions: {includeMetadataChanges: true}})

    const [gameDoc, setGameDoc] = useState({})
    const [payPopupActive, setPayPopupActive] = useState();
    const [hasUserPaid, setHasUserPaid] = useState()
    const [startTime, setStartTime] = useState();
    const [gameStatus, setGameStatus] = useState();
    const [statusLastUpdate, setStatusLastUpdate] = useState(0)
    const [prizePool, setPrizePool] = useState()

    const [isNotification, setIsNotification] = useState();
    const [notificationText, setNotificationText] = useState();
    const [notificationTitle, setNotificationTitle] = useState();
    const [notificationClosable, setNotificationClosable] = useState();

    const [gameAdmin, setGameAdmin] = useState();

    const [now, setNow] = useState(Math.floor(Date.now() / 1000));
    useClock(() => {
        setNow(n => Math.floor(Date.now() / 1000))
    })

    const [triggerLeaderboardUpdate, setTriggerLeaderboardUpdate] = useState(0)

    const [
        authSignature,
        getUserAuth
    ] = useWeb3FirebaseAuth(provider, functions, hasUserPaid, now)

    function setupGameDoc() {
        if (isObjectExists(firebase_game_doc) && !firebase_game_doc_error) {
            let _game = { id: firebase_game_doc.id, ...firebase_game_doc.data() }
            setGameDoc(_game);
            setStartTime(_game.startTime)
        }
    }

    function setupGameAdmin() {
        if (isObjectExists(firebase_operations_doc) && !firebase_operations_error) {
            let operationsData = firebase_operations_doc.data();
            let adminWallet = operationsData.adminWallet.toLowerCase();
            //console.log(adminWallet)
            setGameAdmin(adminWallet);          
        }
    }

    async function loadUserPaid() {
        if (isProviderExists(provider) && contractSlottery && isObjectExists(gameDoc) && !hasUserPaid) {
            let { userEntries, winner, isFree } = gameDoc;

            if (isFree) {
                setHasUserPaid(true)
                setPayPopupActive(false)
                return;
            }

            try {
                // Check if user entered
                let haspaid
                if (userEntries && typeof userEntries === "object" && Object.keys(userEntries).length > 0) {
                    haspaid = userEntries[provider.address.toLowerCase()] || false;
                    if (!haspaid) {
                        let slotteryObj = new Slottery(contractSlottery, provider.signer);
                        haspaid = await slotteryObj.userToGameEntries(provider.address, gameId)
                    }
                    setHasUserPaid(haspaid);
                } else {
                    let slotteryObj = new Slottery(contractSlottery, provider.signer);
                    haspaid = await slotteryObj.userToGameEntries(provider.address, gameId)
                    setHasUserPaid(haspaid)
                }

                let isWinner = !isNullOrUndefined(winner) && ethers.utils.isAddress(winner);
                if (!haspaid && !isWinner)
                    setPayPopupActive(true)
                else
                    setPayPopupActive(false)

            } catch (e) {
                console.log(e)
            }
        }
    }

    function loadStatus() {
        let nextUpdate = 0//statusLastUpdate + 60;
        if (isObjectExists(gameDoc) && now >= nextUpdate) {
            setStatusLastUpdate(now)
            
            let isAdmin = gameDoc[gameDocSpec.fields.isAdmin];
            let startTime = gameDoc[gameDocSpec.fields.startTime];
            let deadline = gameDoc[gameDocSpec.fields.deadline];
            let extraRoundWallets = gameDoc[gameDocSpec.fields.extraRoundWallets];
            let winner = gameDoc[gameDocSpec.fields.winner];

            if (now < startTime) {
                setGameStatus(`Game starts at ${unixTimeStampToDate(startTime)}`);
                return;
            }

            if (!isNullOrUndefined(winner) && ethers.utils.isAddress(winner)) {
                setGameStatus(`Game ended`);
                return;
            }

            if (isArrayExists(extraRoundWallets)) {
                setGameStatus("Extra round for winners")
                return;
            }

            if (now >= startTime) {
                if (isAdmin) {
                    let rounds = gameDoc[gameDocSpec.fields.rounds];
                    let spins = gameDoc[gameDocSpec.fields.spins];

                    if (spins.length === 0) {
                        setGameStatus("Host will spin first round soon.")
                        return;
                    }

                    if (spins.length === rounds) {
                        setGameStatus("All rounds have been spun. Winner will be chosen soon.")
                        return;
                    }

                    if (spins.length < rounds) {
                        setGameStatus(`Next round to be spun: Round #${spins.length + 1}`)
                        return;
                    }
                } else {
                    let deadline = gameDoc[gameDocSpec.fields.deadline];

                    if (now < deadline) {
                        setGameStatus(`You can spin rounds until ${unixTimeStampToDate(deadline)}`);
                        return;
                    }

                    if (now >= deadline) {
                        setGameStatus(`Spining rounds done. Winner will be chosen soon`);
                        return;
                    }
                }
            }

        }
    }

   async function loadPrizePool() {
        if (
            isObjectExists(gameDoc) && 
            isProviderExists(provider) &&
            isObjectExists(contractSlottery) &&
            !prizePool
        ) {
            let slotteryObj = new Slottery(contractSlottery, provider.signer);
            let pool = await slotteryObj.slotteryGameToPrizePool(gameId);
            let prizePoolFormattedString = ethers.utils.formatEther(pool);
            let prizePoolSliceAmount = prizePoolFormattedString.split(".")[0].length + 3
            setPrizePool(ethers.utils.formatEther(pool).slice(0,prizePoolSliceAmount))
            provider.instance.on("block", async () => {
                pool = await slotteryObj.slotteryGameToPrizePool(gameId);
                let prizePoolFormattedString = ethers.utils.formatEther(pool);
                let prizePoolSliceAmount = prizePoolFormattedString.split(".")[0].length + 3
                setPrizePool(ethers.utils.formatEther(pool).slice(0,prizePoolSliceAmount))
            })
        }
    }

    const onPayDone = (e, hasPaid) => {
        if (!hasUserPaid && !hasPaid) {
            return;
        } else {
            setPayPopupActive(false)
        }
    }

    useEffect(() => {
        setupGameDoc();
    }, [firebase_game_doc, firebase_game_doc_error]);

    useEffect(() => {
        setupGameAdmin()
    }, [firebase_operations_doc, firebase_operations_error])

    useEffect(() => {
        loadUserPaid()
        loadPrizePool()
    }, [provider, contractSlottery, gameDoc]);

    // Set status
    useEffect(() => {
        loadStatus()
    }, [gameDoc])

    useEffect(() => {
        if (hasUserPaid && !authSignature) {
            if (!notificationTitle || !notificationTitle.includes("signature")) {
                setNotificationTitle("Please sign the signature")
                setNotificationText("Please wait for your wallet signature to load.")
            }            
            setNotificationClosable(false)
            setIsNotification(true);
        }

        if (hasUserPaid && authSignature) {
            setNotificationText("")
            setNotificationTitle("")
            setNotificationClosable(false)
            setIsNotification(false);
        }
    }, [hasUserPaid, authSignature])

  return (
    <>
        <PayPopup
            active={payPopupActive}
            onClose={onPayDone}
            provider={provider}
            firestore={firestore}
            chosenGame={gameDoc}
            onPayDone={onPayDone}
        />
        <NotificationPopup
            active={isNotification}
            title={notificationTitle}
            text={notificationText}
            onClose={() => {
                if (notificationClosable) {
                    setIsNotification(false)
                }
            }}
        />
        <GameHeader
            gameId={gameId}
            prize={gameDoc?.prize}
            status={gameStatus}
            winner={gameDoc?.winner}
            pool={prizePool}
        />
        <section style={{ backgroundImage: `url("img/contact_bg.png")` }} className="game-section">
            <Container fluid>
            <Row>
                <div className="chat-col">
                    <ChatBox
                        provider={provider}
                        firestore={firestore}
                        gameId={gameId}
                    />
                </div>
                <div className="wheel-col">
                    <GameCore
                        provider={provider}
                        gameDoc={gameDoc}
                        gameId={gameId}
                        firestore={firestore}
                        functions={functions}
                        authSignature={authSignature}
                        startTime={startTime}
                        now={now}
                        setIsNotification={setIsNotification}
                        setNotificationTitle={setNotificationTitle}
                        setNotificationText={setNotificationText}
                        setNotificationClosable={setNotificationClosable}
                        gameAdmin={gameAdmin}
                        setTriggerLeaderboardUpdate={setTriggerLeaderboardUpdate}
                        getUserAuth={getUserAuth}
                    />
                </div>
                <div className="leaderboard-col">
                    <LeaderBoard
                        gameDoc={gameDoc}
                        firestore={firestore}
                        triggerUpdate={triggerLeaderboardUpdate}
                    />
                </div>
            </Row>
            </Container>
            <div className="bg"><img src="img/upcoming_bg.png" alt=""/></div>
        </section>
    </>
  )
}

export default ProviderHOC(Index)
