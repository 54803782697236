import React from 'react';

import './index.css';

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    // /this.state = {
    // /  selectedItem: null,
    // /};
    //this.selectItem = this.selectItem.bind(this);
  }

  //selectItem() {
  //  if (this.state.selectedItem === null) {
  //    const selectedItem = Math.floor(Math.random() * this.props.items.length);
  //    if (this.props.onSelectItem) {
  //      this.props.onSelectItem(selectedItem);
  //    }
  //    this.setState({ selectedItem });
  //  } else {
  //    this.setState({ selectedItem: null });
  //    setTimeout(this.selectItem, 500);
  //  }
  //}

  render() {
    const { selectedItem, items, selectItem } = this.props;
    const wheelVars = {
      '--nb-item': items.length,
      '--selected-item': selectedItem,
    };
    const spinning = selectedItem !== null ? 'spinning' : '';

    return (
      <div className="wheel-container">
        <div className={`wheel ${spinning}`} style={wheelVars}>
          {items.map((item, index) => (
            <div className="wheel-item" key={index} style={{ '--item-nb': index }} >
              {/*{item}*/}
              <span className="number">{item}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
