import { useState, useEffect } from 'react';

export default function useChangeableObject(obj) {
    const [reference, setReference] = useState();

    useEffect(() => {  
        if (obj && !reference) {
            setReference(JSON.stringify(obj))
        }
    }, [obj])

    function hasReferenceChanged(obj) {
        let serialized = JSON.stringify(obj)
        if (serialized !== reference) {
            setReference(serialized)
            return true;
        }
        return false;
    }

    return [hasReferenceChanged]
}