import React from 'react'
import { Col, Row, Container, Form } from 'react-bootstrap'
import Button from "../../components/Buttons";
import MoveAction from "../../components/Buttons/MoveAction";

const Contact = ({ scrollRef, goToScroll }) => (
  <div className="contact" ref={scrollRef} style={{ backgroundImage: `url("img/contact_bg.png")` }}>
    <Container>
      <Row>
        <Col lg={{ span: 6 }}>
          <h2 className="title">Get in touch</h2>
          <p>To contact us, please fill out our form. We look forward to hearing from you.</p>
        </Col>
        <Col lg={{ span: 5, offset: 1 }}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control type="text" name="title" placeholder="Name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="email" name="email" placeholder="Email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="text" name="phone" placeholder="Phone" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control as="textarea" name="message" placeholder="Message" rows={8} />
            </Form.Group>
            <Button type="send" text="Send" />
          </Form>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 6 }}>
          <h2 className="sub-title">Join the club</h2>
          <p>Become a member of the strongest and fastest growing DeFi gambling community, Slotie.</p>
          <div className="buttons">
            <Button type="twitter" text="Twitter" href="https://twitter.com/SlotieNft" />
            <Button type="discord" text="Discord" href="https://discord.com/invite/S4MNX7RkP9" className="ms-4" />
          </div>
        </Col>
        <Col lg={{ span: 2, offset: 4 }} className="align-self-end">
          <MoveAction type="up" onClick={goToScroll} />
        </Col>
      </Row>
      <Row>
        <Col className="footer">
          <div>© SLOTTERY 2022. All rights reserved.</div>
          <div>Privacy Policy / Careers</div>
        </Col>
      </Row>
    </Container>
  </div>
)

export default Contact
