import React, { useEffect, useState }  from "react";
import { ethers } from "ethers";
import Button from "../../../components/Buttons";
import ConnectWalletMessage from "../../../components/ConnectWalletMessage";
import { Table } from 'react-bootstrap';

import {
    unixTimeStampToDate,
    isArrayExists,
    formatPrize,
    shouldShowPool
} from "../../../utils/index"

export default function GamesList({
    games,
    handleGameClick,
    showWarning
 }) {
    return (
        <>
            <Table responsiv="lg" className="sl-table">
                <thead>
                <tr>
                <th>Upcoming Slotteries</th>
                <th>Time</th>
                <th>Watts Entry Fee</th>                
                <th>Prizes</th>
                <th>Prize pool</th>
                <th>Rounds</th>
                <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    isArrayExists(games) && !showWarning ? games.map(game => {
                        return (
                        <>
                            <tr key={game.id}>
                                <td>Slottery name #{game.id}</td>
                                <td>{unixTimeStampToDate(game.startTime)}</td>
                                <td style={{ whiteSpace: 'noWrap' }}>{game.entryPrice ? ethers.utils.formatEther(game.entryPrice): 0} WATTS</td>
                                <td>{formatPrize(game.prize)}</td>
                                {
                                    shouldShowPool(game.prize) ?
                                        <td>{game.pool} WATTs</td>
                                    :
                                        <td>N/A</td>
                                }                                
                                <td>{game.initialRounds}</td>
                                <td><Button text="Join The Game" type="join" size="small" onClick={() => handleGameClick(game.id)} /></td>
                            </tr>
                        </>
                        )
                    })
                    :
                    null
                }
                {
                    showWarning ?
                        <ConnectWalletMessage />
                    :
                        null
                }
                </tbody>
            </Table>
        </>
    )
}
