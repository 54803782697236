import React, { useEffect, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import './main.scss'
import ConnectWallet from "../../components/ConnectWallet";

const HowToPlay = ({ scrollRef }) => {
  const [className, setClass] = useState('')

  const clickWheel = () => {
    setClass('spin')
  }

  useEffect(() => {
    if (className) {
      setTimeout(() => {
        setClass('')
      }, 800)
    }
  }, [className])

  return (
    <div className="how-to-play" ref={scrollRef} style={{ backgroundImage: `url("img/hwp_bg.png")` }}>
      <Container>
        <Row>
          <Col lg={7} className="m-auto">
            <div className="connect-box d-flex">
              <div className="key"><img src="/img/key.png" alt=""/></div>
              <p className="text">To participate in the game please connect your wallet</p>
              <ConnectWallet />
            </div>
            <div className="game-steps d-flex">
              <div>Connect</div>
              <div>
                <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.91663 0.833313H0.666626L6.49996 8.99998L0.666626 17.1666H5.91663L11.75 8.99998L5.91663 0.833313Z" fill="white"/>
                </svg>
                <svg style={{ marginLeft: '-4px' }} width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.91663 0.833313H0.666626L6.49996 8.99998L0.666626 17.1666H5.91663L11.75 8.99998L5.91663 0.833313Z" fill="white"/>
                </svg>
              </div>
              <div>Choose the game</div>
              <div>
                <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.91663 0.833313H0.666626L6.49996 8.99998L0.666626 17.1666H5.91663L11.75 8.99998L5.91663 0.833313Z" fill="white"/>
                </svg>
                <svg style={{ marginLeft: '-4px' }} width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.91663 0.833313H0.666626L6.49996 8.99998L0.666626 17.1666H5.91663L11.75 8.99998L5.91663 0.833313Z" fill="white"/>
                </svg>
              </div>
              <div>Earn WATTS</div>
            </div>
            <div className="images-cont">
              <div className="back-image-left">
                <img src="/img/spin_dark.png" alt=""/>
              </div>
              <div className="front-image">
                <img className={className} onClick={clickWheel} src="/img/spin.png" alt=""/>
              </div>
              <div className="back-image-right">
                <img src="/img/spin_dark.png" alt=""/>
              </div>
            </div>
            <p className="bottom-text">Play and earn WATTS. Slottery beta is now live.</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HowToPlay
