import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Button from "../../components/Buttons";

import { useCollection } from 'react-firebase-hooks/firestore';

import {
    collection,
    getDocs,
    where,
    query
} from 'firebase/firestore';

import {
    isObjectExists,
    getTruncatedAddress,
    splitArrayIntoChunks
} from "../../utils"
import { useEffect, useState } from 'react';

import { getFireBase } from "../../hooks/useFirebase";

const [firestore,] = getFireBase()

const Leaderboard = () => {
    const [leaderBoardItems, setLeaderBoardItems] = useState([]);

    const [
        slottery_games_value, 
        , 
        slottery_games_error
    ] = useCollection(collection(firestore, "slottery_games"), { snapshotListenOptions: { includeMetadataChanges: true } });

    async function getLeaderboard() {
        if (isObjectExists(slottery_games_value) && !slottery_games_error) {
            let gameData = slottery_games_value.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            let pointsPerWallet = {};
            gameData.forEach(game => {
                let { userPoints } = game;
                Object.keys(userPoints).forEach(wallet => {
                    let lcWallet = wallet.toLowerCase();
                    let points = userPoints[wallet];

                    if (!pointsPerWallet[lcWallet]) {
                        pointsPerWallet[lcWallet] = points;
                    } else {
                        pointsPerWallet[lcWallet] += points;
                    }
                })
            });

            pointsPerWallet = Object.keys(pointsPerWallet).map(wallet => ({ wallet, points: pointsPerWallet[wallet] }));
            pointsPerWallet.sort((a,b) => b.points - a.points);

            let _leaderBoardItems = pointsPerWallet.slice(0, 7);

            let wallets = _leaderBoardItems.map(u => u.wallet.toLowerCase())
            wallets = splitArrayIntoChunks(wallets, 10);

            let addressToDiscord = {}

            for (let chunk of wallets) {
                let q = query(collection(firestore, "wallets"), where("address" , "in", chunk));

                let querySnapShot = await getDocs(q)

                let _walletData = querySnapShot.docs.map((doc) => {
                    return doc.data()
                })
                
                _walletData.map(d => addressToDiscord[d.address] = d.discord)
            }
           
            _leaderBoardItems = _leaderBoardItems.map(u => {
                let disc = addressToDiscord[u.wallet]
                
                if (disc && disc !== "") {
                    return {
                        discord: disc,
                        points: u.points
                    }
                }

                return u
            });

            setLeaderBoardItems(_leaderBoardItems);
        }
    }

    useEffect(() => {
        getLeaderboard();
    }, [slottery_games_value])

    return (
        <div className="leaderboard-cont" style={{ backgroundImage: `url("img/leaderboard_bg.png")` }}>
            <Container>
            <Row>
                <Col lg={{ span: 5 }}>
                <div className="left-cont">
                    <div className="table-cont">
                    <h3>Leaderboard</h3>
                    <div className="table-lists">
                        <Row className="header">
                            <Col>User</Col>
                            <Col>Points</Col>
                        </Row>

                        {
                            leaderBoardItems.length > 0 ?
                                leaderBoardItems.map(lbi => {
                                    return (
                                        <Row className="item">
                                            <Col>{lbi.discord ? lbi.discord: getTruncatedAddress(lbi.wallet)}</Col>
                                            <Col>{lbi.points}</Col>
                                        </Row>
                                    )
                                })
                                : null
                        }
                    </div>
                    </div>
                </div>
                </Col>
                <Col lg={{ span: 6, offset: 1 }}>
                <div className="right-cont">
                    <h2>Leaderboard</h2>
                    <p className="mt-4">With the Leaderboard, you can see how many prize points you have accumulated and what rank you hold.</p>
                    <p className="mt-4">Points can be exchanged for multiple rewards in the Slottery dashboard from WATTS, to NFTs, and more.</p>
                    <Button text="Join The Game" type="join" />
                </div>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Leaderboard
