import { useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";

import { getProviders } from "../utils/providers";

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: getProviders(),
  disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

export default function useConnect(existingProvider) {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [provider, setProvider] = useState(existingProvider);

  async function doConnect() {
    setIsConnecting(true);
    web3Modal
      .connect()
      .then(async (proxy) => {
        const instance = new ethers.providers.Web3Provider(proxy);

        instance.on("chainChanged", (chainId) => {
            window.location.reload();
        });

        instance.on("accountsChanged", (accounts) => {
            window.location.reload();
        });

        const signer = await instance.getSigner();
        const address = await signer.getAddress();
        let { chainId } = await instance.getNetwork();

        setProvider({
          instance,
          signer,
          address,
          chainId,
        });
        setIsConnected(true);
        setIsConnecting(false);
      })
      .catch((e) => {
        setIsConnecting(false);
      });
  }

  async function doDisconnect() {
    setProvider(null)
    await web3Modal.clearCachedProvider();
    setTimeout(() => {
        window.location.reload();
    }, 1);
  }

  // useEffect(() => {
  //   doConnect()
  // }, [])

  return [isConnected, isConnecting, doConnect, doDisconnect, provider];
}
