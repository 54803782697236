import React, { useState } from "react"
import { Col } from 'react-bootstrap'
import { Oval } from  'react-loader-spinner'

export default function BetItem({
    round,
    maxSpinNum,
    placedBet,
    spunNumber,
    onBet,
    loading,
    disabled
}) {
    
    const [betValue, setBetValue] = useState(1)
    
    const onChangeValue = (type) => {
        let newValue =  (type === 'plus' ? betValue + 1 : betValue - 1);        

        if ((type === 'minus' && betValue <= 1)) {
            return;
        }

        if ((type === 'plus' && betValue >= maxSpinNum)) {
            return;
        }

        setBetValue(newValue)
    }

    const onEnterBet = (e) => {
        let newValue =  parseInt(e.target.value)

        if (!isNaN(newValue) && (newValue < 1)) {
            return;
        }

        if (!isNaN(newValue) && (newValue > maxSpinNum)) {
            return
        }

        setBetValue(newValue)
    }

    return (
        <>           
            <Col lg={6} key={round}>
                <div className="item" style={{justifyContent: "left"}}>
                    <div className="round">{round === "extra" ? "Extra round" : "Round " + round }</div>
                    {
                            placedBet > 0 ?
                            <div className="" style={{marginLeft: "10px"}}>My Bet: {placedBet}</div>
                            :
                            <></>
                    }
                    {
                            spunNumber > 0 ?
                            <div className="" style={{marginLeft: "10px"}}>Winning number: {spunNumber}</div>
                            :
                            <></>
                    }
                    {
                            placedBet + spunNumber === 0 ?
                            <div className="buttons">
                                <div className="inputs">
                                    <div className="input-box">
                                        <input value={betValue} onChange={(e) => onEnterBet(e)} type="number" />
                                        {
                                            !loading ?
                                            <div className="actions">
                                                <button onClick={() => onChangeValue('minus')} style={{ marginRight: 5 }}><svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="10" height="2" fill="white"/></svg></button>
                                                <button onClick={() => onChangeValue('plus')}><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="4" width="10" height="2" fill="white"/><rect x="4" width="2" height="10" fill="white"/></svg></button>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    {
                                        !loading ?
                                            <button
                                                className="spin"
                                                type="join"
                                                disabled={disabled}
                                                onClick={() => onBet(betValue)}
                                            >
                                                Bet
                                            </button>
                                        :
                                            <Oval
                                                height="40"
                                                width="40"
                                                color='#ffffff'
                                                ariaLabel='loading'
                                            />
                                    }
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>
            </Col>                        
        </>
    )
}