import React from 'react'
import Button from "../Buttons";
import './popup.scss'

import { ethers } from 'ethers';

import Watts from "../../adapters/watts";
import Slottery from "../../adapters/slottery"
import Loader from "../Loader";

const AutoSpinPopup = ({ active = false, onClose, onConfirm, isAutoSpin }) => {

  return (
    <div className={`popup-wrapper${active ? ' active' : ''}`}>
      <div className="popup-content">
        <div className="popup-body">
          <div className="head">
            <h4>Auto Spin</h4>
            <span onClick={onClose}>&times;</span>
          </div>
          <div className="content">
            <p>Are you sure you want to turn Auto-Spin {isAutoSpin ? "off" : "on"}?</p>            
            <hr/>            
                <Button text="Close" size="small" type="dark" block style={{ marginRight: 15 }} onClick={onClose} />
                <Button text={isAutoSpin ? "Turn Off" : "Turn On"} size="small" type="primary" block 
                    onClick={async () => {
                        onConfirm();
                        onClose()
                    }
                }/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AutoSpinPopup
