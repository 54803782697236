import { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';

import { 
    isProviderExists,
    isObjectExists,
    getUnixTimeStamp
} from "../utils/index"

import { getAuthStorageData, saveAuthStorageData } from "../services/authStorage"

let AuthValidTime = 86400 * 365;

export default function useWeb3FirebaseAuth(provider, functions, paid, now) {
    const [authSignature, setAuthSignature] = useState();
    const [loading, setLoading] = useState();

    async function getUserAuth() {
        if (isProviderExists(provider) && paid && isObjectExists(functions)) {
            setLoading(true);
            setAuthSignature();

            let currentMessage;
            let currentTimestamp;
            let now = getUnixTimeStamp();

            const slotteryWeb3Auth = httpsCallable(functions, 'slotteryWeb3Auth');
            const userStoredAuthData = getAuthStorageData(provider.address)
            
            if (!userStoredAuthData || now > userStoredAuthData.timestamp) {
                try {
                    let res = await slotteryWeb3Auth({ address: provider.address })
                    if (res && res.data && res.data.success) {
                        let { message, timestamp } = res.data;
                        currentMessage = message;
                        currentTimestamp = timestamp;                       
                    } else {
                        console.log("AUTH ERROR - no storage - response failed")
                        console.log(res)
                    }
                } catch (e) {
                    console.log("AUTH ERROR - no storage - error")
                    alert(e);
                }
            } else {
                currentMessage = userStoredAuthData.message;
                currentTimestamp = userStoredAuthData.timestamp;
            }
      
            let sig = await provider.signer.signMessage(currentMessage);
            setAuthSignature(sig);           
            saveAuthStorageData(provider.address, currentMessage, currentTimestamp);
            setLoading(false)
        }
    }

    useEffect(() => {
        getUserAuth();
    }, [provider, paid, functions])

    return [
        authSignature,
        getUserAuth
    ]

}